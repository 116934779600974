import React, { useState } from 'react';
import {
  Box,
  Flex,
  Button,
  Spacer,
  Divider,
  Link,
  Text,
  RadioGroup,
  Radio,
} from '@chakra-ui/react';
import { DownloadIcon } from '@chakra-ui/icons';
import OrderItemCard from './OrderItemCard';
import OrderLinkCard from './OrderLinkCard';
import { observer } from 'mobx-react-lite';
import { useStore } from '../hooks/useStore';
import PayForm from './PayForm';

function OrderCardBody({ order }) {
  const { user } = useStore();
  const [pay, setPay] = useState(false);
  const [value, setValue] = useState('1');
  const [del, setDel] = useState([]);

  return (
    <>
      {order.items.map((item) => (
        <OrderItemCard key={item.id} item={item} order={order} />
      ))}
      {order.linkItems.map((link) => (
        <OrderLinkCard
          key={link.id}
          item={link}
          order={order}
          del={del}
          setDel={setDel}
        />
      ))}

      {['assembled', 'confirm', 'send', 'delivered', 'issued'].includes(
        order.status
      ) && (
        <>
          <Divider mb="20px" />
          <Box fontSize="sm" mb="20px">
            Пункт выдачи: {order.stock?.address}
          </Box>
          <Flex
            justifyContent="space-between"
            flexDirection={['column', 'column', 'row']}
          >
            <Box fontSize="sm">
              {order.sendDate && (
                <Box>
                  Дата отправки -{' '}
                  {new Date(order.sendDate).toLocaleDateString()}
                </Box>
              )}
              {order.arriveDate && (
                <Box>
                  Дата доставки в Россию -{' '}
                  {new Date(order.arriveDate).toLocaleDateString()}
                </Box>
              )}
              {order.issDate && (
                <Box>
                  Дата выдачи - {new Date(order.issDate).toLocaleDateString()}
                </Box>
              )}
            </Box>
            <Box fontSize="sm">
              {order.sendWeight && (
                <Box>Вес отправки - {order.sendWeight} кг</Box>
              )}
              {order.arriveWeight && (
                <Box>Вес прибытия - {order.arriveWeight} кг</Box>
              )}
              {order.shipPrice && (
                <Flex gap="12px">
                  <Text>Сумма доставки - </Text>
                  <Text
                    fontWeight="bold"
                    color={order.shipPayed ? 'green' : 'red'}
                  >
                    {order.shipPrice}${' '}
                  </Text>
                  <Text fontSize="xs" color={order.shipPayed ? 'green' : 'red'}>
                    {order.shipPayed ? 'Оплачено' : 'Не оплачено'}
                  </Text>
                </Flex>
              )}
            </Box>
          </Flex>
        </>
      )}

      {[
        'modified',
        'invoice',
        'stock',
        'deleted',
        'delivered',
        'price',
      ].includes(order.status) && (
        <>
          <Divider my="20px" />
          <Flex gap="20px">
            <Spacer />
            {['modified', 'price'].includes(order.status) && (
              <>
                <Button
                  size="sm"
                  colorScheme="green"
                  onClick={() => user.updateOrder(order.id, del)}
                >
                  Подтвердить изменения
                </Button>
                <Button
                  size="sm"
                  colorScheme="red"
                  onClick={() => user.trashOrder(order.id)}
                >
                  Удалить заявку
                </Button>
              </>
            )}

            {order.status === 'deleted' && (
              <Button
                size="sm"
                colorScheme="orange"
                onClick={() => user.restoreOrder(order.id)}
              >
                Восстановить
              </Button>
            )}

            {order.status === 'invoice' &&
              (order.bill?.billFile ? (
                <Button
                  as={Link}
                  href={order.bill.billFile}
                  colorScheme="red"
                  leftIcon={<DownloadIcon />}
                >
                  Скачать счет
                </Button>
              ) : (
                <Flex gap="20px">
                  <Text>
                    Счет можно оплатить в течение 3 часов после выставления
                  </Text>
                  <Button
                    size="sm"
                    colorScheme="red"
                    leftIcon={<DownloadIcon />}
                    onClick={() => setPay(true)}
                  >
                    Показать данные для оплаты
                  </Button>
                </Flex>
              ))}

            {order.status === 'stock' && (
              <Flex gap="20px">
                <Box fontSize="sm">
                  <Text>Способ оплаты доставки</Text>
                  <RadioGroup onChange={setValue} value={value} size="sm">
                    <Radio value="1" mr="10px">
                      При получении
                    </Radio>
                    <Radio value="2">При отправке</Radio>
                  </RadioGroup>
                </Box>
                {order?.wrong?.length ? (
                  <Button
                    size="sm"
                    colorScheme="orange"
                    isLoading={user.loading}
                    onClick={() => user.wrongOrder(order.id, order.wrong)}
                  >
                    Отправить несоответствия
                  </Button>
                ) : (
                  <Button
                    size="sm"
                    colorScheme="green"
                    isLoading={user.loading}
                    onClick={() => user.confirmOrder(order.id)}
                  >
                    Подтвердить отправку
                  </Button>
                )}
              </Flex>
            )}

            {order.giveCode && (
              <Flex gap="20px">
                <Box>Код для получения заказа</Box>
                <Box fontSize="lg" color="red">
                  {order.giveCode}
                </Box>
              </Flex>
            )}
          </Flex>
        </>
      )}
      {order.status === 'invoice' && (
        <PayForm isOpen={pay} onClose={() => setPay(false)} order={order} />
      )}
    </>
  );
}

export default observer(OrderCardBody);
