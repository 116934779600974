import {
  Box,
  Divider,
  Heading,
  Accordion,
  Flex,
  Link,
  IconButton,
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import OrderCard from '../components/OrderCard';
import { useStore } from '../hooks/useStore';
import WhatsappIcon from '../components/WhatsappIcon';
import { Navigate } from 'react-router-dom';

const OrdersPage = observer(() => {
  const { user } = useStore();

  useEffect(() => {
    if (user?.userData?.status === 'active') {
      user.getOrders();
    }
  }, []);

  if (user?.userData?.status === 'active') {
    return (
      <>
        <Box>
          <Heading fontWeight="medium" color="gray.600">
            Мои заказы
          </Heading>
          <Flex alignItems="center" gap="10px" my="20px">
            <Box as={Link} href="https://wa.me/+79994244004" isExternal>
              <WhatsappIcon boxSize="30px" />
            </Box>

            <Box>Помощь в случае затруднений при оформлении заказа</Box>
          </Flex>
          <Divider mb="30px" />
          <Accordion allowToggle>
            {user.orders.length
              ? user.orders.map((order) => (
                  <OrderCard key={order.id} order={order} />
                ))
              : 'No orders yet'}
          </Accordion>
        </Box>
      </>
    );
  }
  return <Navigate to="/inactive" />;
});

export default OrdersPage;
