import React, { useState } from 'react';
import {
  Flex,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Text,
  ModalCloseButton,
  Icon,
  Divider,
  Table,
  Tbody,
  Tr,
  Td,
  TableContainer,
} from '@chakra-ui/react';
import FileUpload from '../components/FileUpload';
import { observer } from 'mobx-react-lite';
import { AttachmentIcon } from '@chakra-ui/icons';
import { useStore } from '../hooks/useStore';

const PayForm = ({ order, isOpen, onClose }) => {
  const { user } = useStore();
  const [img, setImg] = useState('');
  console.log(order);
  const fileHendler = (e) => {
    const img = e.target.files[0];
    // setImg(URL.createObjectURL(img));
    setImg(img);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Реквизиты для оплаты</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex gap="20px" mb="10px">
            <Text>Заявка:</Text>
            <Text color="green" fontWeight="bold">
              {order.code}
            </Text>
          </Flex>
          <Flex alignItems="flex-end" mb="20px">
            <Text>Дата выставления счета:</Text>
            <Divider flex="1" />
            <Text color="orange" fontWeight="strong">
              {new Date(order.bill?.billDate).toLocaleDateString()}
            </Text>
          </Flex>
          {order.bill?.payId ? (
            <TableContainer>
              <Table size="sm">
                <Tbody>
                  <Tr>
                    <Td>Банк получателя</Td>
                    <Td>{order.bill.payData.bank}</Td>
                  </Tr>
                  <Tr>
                    <Td>Имя получателя</Td>
                    <Td>{order.bill.payData.name}</Td>
                  </Tr>
                  <Tr>
                    <Td>ИНН</Td>
                    <Td>{order.bill.payData.INN}</Td>
                  </Tr>
                  <Tr>
                    <Td>ОГРН</Td>
                    <Td>{order.bill.payData.OGRN}</Td>
                  </Tr>
                  <Tr>
                    <Td>Расчетный счет</Td>
                    <Td>{order.bill.payData.RS}</Td>
                  </Tr>
                  <Tr>
                    <Td>БИК</Td>
                    <Td>{order.bill.payData.BIK}</Td>
                  </Tr>
                  <Tr>
                    <Td>Кор. Счет</Td>
                    <Td>{order.bill.payData.KorS}</Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>
          ) : (
            <Flex gap="20px">
              <Text>Номер карты:</Text>
              <Text fontSize="xl" color="orange" fontWeight="bold">
                {order.bill?.cardNumber}
              </Text>
            </Flex>
          )}

          <Flex gap="20px" mt="20px">
            <Text>Сумма к оплате:</Text>
            <Text color="green" fontWeight="bold">
              {order.convertedSumm} &#8381;
            </Text>
          </Flex>
        </ModalBody>

        <ModalFooter>
          {img ? (
            <Icon as={AttachmentIcon} mr="20px" />
          ) : (
            <FileUpload accept={'image/*'} hendler={fileHendler}>
              <Button mr={3}>Прикрепить квитанцию</Button>
            </FileUpload>
          )}
          {img ? (
            <Button
              colorScheme="green"
              onClick={() => user.sendTicket(order.id, img, onClose)}
              isLoading={user.loading}
            >
              Отправить
            </Button>
          ) : (
            <Button colorScheme="blue" onClick={onClose}>
              Закрыть
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
export default observer(PayForm);
