import React from 'react';
import {
  Box,
  Button,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Icon,
  Divider,
  Avatar,
  Flex,
  Text,
  Spacer,
  Tag,
} from '@chakra-ui/react';
import {
  BiCart,
  BiLogOut,
  BiLogIn,
  BiCube,
  BiUserCircle,
  BiChat,
  BiCog,
  BiArchiveIn,
  BiCartDownload,
  BiLink,
} from 'react-icons/bi';
import EnterMenu from './EnterMenu';
import { Link as rLink } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStore } from '../hooks/useStore';

const UserControl = () => {
  const { user } = useStore();

  return (
    <>
      {user.isAuth ? (
        <Flex alignSelf="end" gap="15px" alignItems="center">
          <Box display={['none', 'none', 'block']}>
            <IconButton
              as={rLink}
              to="/cart"
              icon={<Icon boxSize={8} as={BiCart} />}
              borderRadius="full"
              variant="ghost"
              aria-label="Cart"
            />
            {!!user.cart?.items?.length && (
              <Tag
                colorScheme="red"
                borderRadius="full"
                size="sm"
                w="12px"
                h="12px"
                ml="-12px"
                position="absolute"
                variant="solid"
                zIndex="1"
              >
                {user.cart?.items.length}
              </Tag>
            )}
          </Box>
          <Box>
            <IconButton
              as={rLink}
              to="/linkorder"
              borderRadius="full"
              variant="ghost"
              aria-label="Lincorder"
              icon={<Icon boxSize={8} as={BiLink} />}
            />
          </Box>
          <Box display={['none', 'none', 'block']}>
            <IconButton
              as={rLink}
              to="/orders"
              icon={<Icon boxSize={8} as={BiCube} />}
              borderRadius="full"
              variant="ghost"
              aria-label="Orders"
            />
            {!!user?.orders.length && (
              <Tag
                colorScheme="red"
                borderRadius="full"
                size="sm"
                w="12px"
                h="12px"
                ml="-12px"
                position="absolute"
                variant="solid"
                zIndex="1"
              >
                {user?.orders.length}
              </Tag>
            )}
          </Box>
          <Menu colorScheme="blue">
            <MenuButton variant="ghost">
              <Avatar
                src={user.userData.avatar}
                size={['md', 'md', 'lg']}
                border="4px"
              />
            </MenuButton>
            <MenuList>
              <HStack px="20px" py="15px">
                <Text color="gray.500" fontWeight="medium">
                  {user.userData.login || user.userData.email}
                </Text>
                <Spacer />
                <IconButton
                  as={rLink}
                  to="/user"
                  mr="20px"
                  size="sm"
                  icon={<BiCog />}
                />
              </HStack>
              <Divider />
              {/* <MenuItem as={rLink} to="/user">
                <Icon mr="20px" as={BiChat} />
                Notifications
              </MenuItem> */}
              <Divider />
              <MenuItem as={rLink} to="/orders">
                <Icon mr="20px" as={BiCube} />
                Заказы
                {!!user?.orders.length && (
                  <Tag
                    colorScheme="red"
                    borderRadius="full"
                    size="sm"
                    w="12px"
                    h="12px"
                    ml="20px"
                    variant="solid"
                  >
                    {user?.orders.length}
                  </Tag>
                )}
              </MenuItem>
              <MenuItem as={rLink} to="/cart">
                <Icon mr="20px" as={BiCart} />
                Корзина
                {!!user.cart?.items?.length && (
                  <Tag
                    colorScheme="red"
                    borderRadius="full"
                    size="sm"
                    w="12px"
                    h="12px"
                    variant="solid"
                    ml="20px"
                  >
                    {user.cart?.items.length}
                  </Tag>
                )}
              </MenuItem>
              <Divider />
              <MenuItem onClick={() => user.logout()}>
                <Icon mr="20px" as={BiLogOut} />
                Выйти
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      ) : (
        <EnterMenu />
      )}
    </>
  );
};

export default observer(UserControl);
