import { Box, Image } from '@chakra-ui/react';
import React from 'react';

const ImgPrew = ({ img, click, select }) => {
  return (
    <Box
      p="2px"
      mb="10px"
      onClick={() => click(img.id)}
      borderWidth="2px"
      borderColor={select ? '#FCC330' : 'transparent'}
      cursor="pointer"
    >
      <Image boxSize="50px" objectFit="cover" src={img.link} />
    </Box>
  );
};

export default ImgPrew;
