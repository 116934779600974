import React, { useEffect, useState } from 'react';
import { Center, Grid, Spinner } from '@chakra-ui/react';
import Banner from '../components/Banner';
import ItemCard from '../components/ItemCard';
import Pagination from '../components/Pagination';
import { observer } from 'mobx-react-lite';
import { useStore } from '../hooks/useStore';

const CatalogPage = observer(() => {
  const { store } = useStore();
  const [items, setItems] = useState([]);

  // useEffect(() => {
  //   setItems([...items, ...store.items]);
  // }, [store.items]);

  useEffect(() => {
    return () => {
      store.setItems([]);
      store.getCategories(true);
    };
  }, []);

  useEffect(() => {
    setItems(store.items);
    // setItems([...items, ...store.items]);
  }, [store.items]);

  return (
    <>
      <Banner></Banner>
      {!store.loading ? (
        <Grid
          templateColumns={[
            'repeat(2, 1fr)',
            'repeat(2, 1fr)',
            'repeat(4, 1fr)',
            'repeat(5, 1fr)',
          ]}
          gap={['15px', '20px', '30px']}
        >
          {items.length || store.loading
            ? items.map((card) => <ItemCard key={card.id} item={card} />)
            : !!store.count || 'Товаров не найдено'}
        </Grid>
      ) : (
        <Center mt="30px">
          <Spinner />
        </Center>
      )}

      <Pagination
        callback={(...args) => store.getItems(...args)}
        itemsCount={store.count}
        isLoading={store.loading}
      />
    </>
  );
});

export default CatalogPage;
