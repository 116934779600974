import React from 'react';
import {
  Box,
  HStack,
  VStack,
  Icon,
  Input,
  Text,
  Textarea,
  Spacer,
  Button,
  Flex,
  IconButton,
  Divider,
} from '@chakra-ui/react';
import { useState } from 'react';
import { BiTrash, BiCheck } from 'react-icons/bi';
import { useStore } from '../hooks/useStore';
import { observer } from 'mobx-react-lite';

const LinkCartForm = ({ check }) => {
  const { user } = useStore();
  const [link, setLink] = useState('');
  const [description, setDescription] = useState('');
  const [count, setСount] = useState(1);

  const addHendler = async () => {
    await user.addLink({ link, description, count });
    setLink('');
    setDescription('');
    setСount(1);
  };

  return (
    <>
      <Flex
        flexDirection={['column', 'column', 'row']}
        alignItems="end"
        mt="20px"
      >
        <Box ml={['0', '0', '30px']} w="full">
          <Input
            placeholder="Ссылка на товар"
            size="xs"
            value={link}
            onChange={(e) => setLink(e.target.value)}
          />
          <Box mt="10px">
            <Text ml="10px" mb="5px">
              Коментарии к товару
            </Text>
            <Textarea
              placeholder="Размер, цвет"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Box>
          <Flex
            mt="20px"
            gap="20px"
            alignItems="center"
            justifyContent="flex-end"
          >
            <Text>Колличество</Text>
            <Box>
              <Input
                placeholder="Колличество"
                value={count}
                onChange={(e) => setСount(e.target.value)}
              />
            </Box>
          </Flex>
        </Box>

        <Box ml="20px" mt="10px">
          <IconButton
            colorScheme="green"
            onClick={addHendler}
            mb="0"
            isLoading={user.loading}
            icon={<Icon as={BiCheck} />}
            isDisabled={!link || !description}
          />
        </Box>
      </Flex>
      <Divider mt="10px" />
    </>
  );
};

export default observer(LinkCartForm);
