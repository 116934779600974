import React from 'react';
import { Box, Flex, Image } from '@chakra-ui/react';
import MainMenu from './MainMenu';
import Search from './Search';
import UserControl from './UserControl';
import { Link as rLink } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { SITE_URL } from '../http';

const Header = observer(() => {
  const url = SITE_URL;
  return (
    <Box
      w="full"
      pb="5px"
      boxShadow="lg"
      bgGradient="radial-gradient(100% 11729.68% at 19.9% 0%, #007A4C 0%, #9DC41A 28.65%, #FCC330 74.72%, #C81742 100%)"
      borderBottomRadius="md"
    >
      <Box h={['2px', '3px', '5px']}></Box>
      <Box
        bg="whiteAlpha.500"
        mixBlendMode="overlay"
        pos="absolute"
        h={['20px', '30px', '40px']}
        w="full"
        zIndex="0"
      ></Box>
      <Flex
        px={['10px', '10px', '20px']}
        maxW="1300px"
        m="auto"
        mb="5px"
        alignItems={['stretch', 'stretch', 'end']}
        zIndex="10"
        pos="relative"
        justify="center"
        flexDirection={['column', 'column', 'row']}
      >
        <Flex alignItems="end">
          <MainMenu />

          <Flex
            alignItems="end"
            py={['5px', '5px', '10px']}
            px={['15px', '15px', '28px']}
            bg="white"
            borderBottomRadius="md"
          >
            <Box as={rLink} to="/" zIndex="50">
              <Image
                src={`${url}/minLogo.svg`}
                alt="logo"
                h={['50px', '50px', '70px']}
              />
            </Box>
          </Flex>
        </Flex>

        <Flex
          flex="1"
          h="64px"
          flexDirection={['column-reverse', 'column-reverse', 'row']}
          gap={['10px', '10px', '20px']}
          mt={['-50px', '-50px', '0']}
          mb={['auto', 'auto', '-11px']}
          alignItems={['stretch', 'stretch', 'center']}
        >
          <Search />
          <UserControl />
        </Flex>
      </Flex>
    </Box>
  );
});

export default Header;
