import { Box, Link } from '@chakra-ui/react';
import React from 'react';
import { Link as rLink } from 'react-router-dom';

export default function ConfirmPage() {
  return (
    <Box>
      E-mail подтвержден. Теперь вы можете{' '}
      <Link as={rLink} to="/login">
        войти
      </Link>{' '}
      в личный кабинет.
    </Box>
  );
}
