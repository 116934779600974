import React from 'react';
import { Box, Image } from '@chakra-ui/react';

const Banner = () => {
  return (
    <Box
      h={['170px', '250px', '350px', '450px']}
      bg="gray.200"
      borderRadius="4px"
      marginBottom={['20px', '20px', '50px']}
      overflow="hidden"
    >
      <Image
        src="img.webp"
        h="full"
        w="full"
        objectFit="cover"
        backgroundPosition="bottom"
      />
    </Box>
  );
};

export default Banner;
