import React, { useEffect, useRef } from 'react';
import { Center, Grid, Spinner } from '@chakra-ui/react';
import CatBanner from '../components/CatBanner';
import ItemCard from '../components/ItemCard';
import Pagination from '../components/Pagination';
import { observer } from 'mobx-react-lite';
import { useStore } from '../hooks/useStore';
import CategoryCard from '../components/CategoryCard';
import Banner from '../components/Banner';

const CategoriesPage = observer(() => {
  const { store } = useStore();

  useEffect(() => {
    store.getCategories(true);
  }, []);

  return (
    <>
      <Banner />
      {/* <CatBanner title={'Категории'} /> */}
      <Grid
        templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)', 'repeat(3, 1fr)']}
        gap={[4, 4, 8]}
        alignContent="stretch"
      >
        {store.categories.length ? (
          store.categories.map((card) => (
            <CategoryCard key={card.id} cat={card} />
          ))
        ) : (
          <Center mt="30px">
            <Spinner />
          </Center>
        )}
      </Grid>
    </>
  );
});

export default CategoriesPage;
