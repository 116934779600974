import React from 'react';
import { Box, Flex, Image, Text } from '@chakra-ui/react';
import { Link as rLink } from 'react-router-dom';

const BannerSmall = ({ title, to, img }) => {
  return (
    <Box mb={['20px', '20px', '50px']} h="200px">
      <Flex
        mt="50px"
        pos="relative"
        flexDirection="column"
        alignItems="center"
        h={['150px', '150px', '150px']}
        borderTopRadius="md"
        bgColor="#FFFFFF"
        bgGradient="radial-gradient(23.49% 151.9% at 2.43% 0%, #007A4C 0%, #9DC41A 27.73%, rgba(255, 255, 255, 0) 100%), radial-gradient(60.92% 157.26% at 100% 0%, rgba(200, 23, 66, 0.8) 0%, rgba(252, 195, 48, 0.8) 48.96%, rgba(216, 216, 216, 0) 100%)"
        overflow="visible"
      >
        {!!img && (
          <>
            <Image
              src={img}
              pos="absolute"
              h="150px"
              w="300px"
              mixBlendMode="overlay"
              objectFit="cover"
              right="-20px"
            />
            <Image
              src={img}
              pos="absolute"
              transform="scaleX(-1)"
              h="150px"
              w="200px"
              mixBlendMode="overlay"
              objectFit="cover"
              left="-20px"
            />
          </>
        )}
        <Flex
          h="200px"
          mt="-50px"
          pos="relative"
          alignItems="flex-end"
          justifyContent="space-around"
        >
          {to ? (
            <Text
              as={rLink}
              to={to}
              fontSize={['20px', '20px', '50px']}
              mb="20px"
              fontWeight="medium"
              color="blackAlpha.700"
            >
              {title}
            </Text>
          ) : (
            <Text
              fontSize={['20px', '30px', '50px']}
              mb="20px"
              fontWeight="medium"
              color="blackAlpha.700"
            >
              {title}
            </Text>
          )}
          {!!img && <Image src={img} h="200px" objectFit="contain" />}
        </Flex>
      </Flex>
      <Box
        w="full"
        h="15px"
        borderBottomRadius="md"
        bgGradient="radial-gradient(100% 11729.68% at 19.9% 0%, #007A4C 0%, #9DC41A 28.65%, #FCC330 74.72%, #C81742 100%)"
      ></Box>
    </Box>
  );
};

export default BannerSmall;
