import axios from 'axios';

// export const SITE_URL = 'http://localhost:3006';
export const SITE_URL = 'https://turkpazar.ru';
// export const API_URL = 'http://localhost:5000/api';
export const API_URL = 'https://api.turkpazar.ru/api';

const $api = axios.create({
  withCredentials: true,
  baseURL: API_URL,
});

$api.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${localStorage.getItem('tk')}`;
  return config;
});

$api.interceptors.response.use(
  (config) => {
    return config;
  },
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response.status == 401 &&
      error.config &&
      !error.config._isRetry
    ) {
      originalRequest._isRetry = true;
      try {
        const response = await axios.get(`${API_URL}/auth/refresh`, {
          withCredentials: true,
        });
        localStorage.setItem('tk', response.data.accessToken);
        return $api.request(originalRequest);
      } catch (e) {
        // console.log('No authorize');
      }
    }
    throw error;
  }
);

export default $api;
