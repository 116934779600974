import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Flex,
  Heading,
  Center,
  Spinner,
  Image,
  Button,
  Text,
  Divider,
  InputGroup,
  InputRightElement,
  InputLeftElement,
  useNumberInput,
  Input,
  Link,
} from '@chakra-ui/react';
import { ChevronRightIcon, SmallCloseIcon, DeleteIcon } from '@chakra-ui/icons';
import { useNavigate, useParams } from 'react-router-dom';
import useMessage from '../hooks/useMessage';
import { useStore } from '../hooks/useStore';
import { observer } from 'mobx-react-lite';
import { Link as rLink } from 'react-router-dom';
import ImgPrew from '../components/ImgPrew';

const ItemViewPage = observer(() => {
  const id = Number(useParams().id);
  const { store, user } = useStore();
  const navigate = useNavigate();

  const [showImg, setShow] = useState('');

  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
    useNumberInput({
      step: 1,
      defaultValue: 1, //cart count
      min: 0,
    });
  const inc = getIncrementButtonProps();
  const dec = getDecrementButtonProps();
  const input = getInputProps();

  const prewImg = useRef();

  const addHandler = () => {
    if (user.isAuth) {
      if (user.userData.status === 'active') {
        user.addToCart(store.item.id, 1);
      } else {
        navigate('/inactive');
      }
    } else {
      navigate('/login');
    }
  };

  useEffect(() => {
    store.getItem(id);
    return () => {
      store.setItem(null);
    };
  }, [id]);

  useEffect(() => {
    if (store.item?.images?.length) setShow(store.item?.images[0]?.link);
  }, [store.item]);

  // useMessage(store.error, () => store.setError(null));

  const preview = (id) => {
    const current = store.item.images.find((img) => img.id === id);
    prewImg.current.src = current.link;
    setShow(current.link);
  };

  if (store.loading || !store.item) {
    return (
      <Flex m="auto">
        <Center flex="1">
          <Spinner thickness="4px" size="xl" color="green" />
        </Center>
      </Flex>
    );
  }
  return (
    <Box>
      <Heading mb="30px" fontWeight="medium" color="gray.600">
        {store.item.name}
      </Heading>
      <Flex
        gap="20px"
        alignItems={['stretch', 'stretch', 'start']}
        flexDirection={['column', 'column', 'row']}
      >
        <Flex gap="20px">
          <Box>
            {store.item.images.map((img) => (
              <ImgPrew
                key={img.id}
                img={img}
                click={preview}
                select={img.link === showImg}
              />
            ))}
          </Box>
          <Box w={['200px', '240px', '400px', '600px']} maxH="600">
            {!!store.item.images.length && (
              <Image
                ref={prewImg}
                boxSize="full"
                objectFit="contain"
                src={
                  store.item.images[0] ? store.item.images[0].link : 'Default'
                }
              />
            )}
          </Box>
        </Flex>
        <Box flex="1" minW="250px">
          <Box mb="10px" color="#007A4C">
            <Link
              as={rLink}
              to={`/catalog/${store.item.subcat?.items_cat?.id}`}
            >
              {store.item.subcat?.items_cat?.name}
            </Link>
            <ChevronRightIcon mx="10px" />
            <Link
              as={rLink}
              to={`/catalog/${store.item.subcat?.items_cat?.id}/${store.item.subcat.id}`}
            >
              {store.item.subcat?.name}
            </Link>
          </Box>

          <Box mb="10px" bg="#f4fae3" borderRadius="md" px="10px">
            <Text as="span" fontSize="xs">
              Назначение:
            </Text>{' '}
            <Text as="span" color="#C81742">
              {store.item.destination}
            </Text>
          </Box>

          <Divider mb="30px" />

          <Box mb="30px">{store.item.description}</Box>

          <Box
            maxW={['auto', 'auto', '300px']}
            fontSize="xs"
            color="gray.500"
            p="10px"
            bg="#f4fae3"
            borderRadius="md"
          >
            <Text mb="10px" fontWeight="bold">
              Упаковка:
            </Text>
            <Box flex="1">
              <Flex>
                <Box>
                  <Text as="span">Ш:</Text>
                  <Text as="span" mx="4px" fontWeight="bold" color="black">
                    {store.item.pkg_x}
                  </Text>
                  <Text as="span">см</Text>
                </Box>
                <SmallCloseIcon boxSize={4} color="gray.400" mx="10px" />
                <Box>
                  <Text as="span">В:</Text>
                  <Text as="span" mx="4px" fontWeight="bold" color="black">
                    {store.item.pkg_y}
                  </Text>
                  <Text as="span">см</Text>
                </Box>
                <SmallCloseIcon boxSize={4} color="gray.400" mx="10px" />
                <Box>
                  <Text as="span">Г:</Text>
                  <Text as="span" mx="4px" fontWeight="bold" color="black">
                    {store.item.pkg_z}
                  </Text>
                  <Text as="span">см</Text>
                </Box>
              </Flex>
              <Flex>
                <Text as="span">Вес:</Text>
                <Text as="span" mx="4px" fontWeight="bold" color="black">
                  {store.item.pkg_w}
                </Text>
                <Text as="span">кг</Text>
              </Flex>
            </Box>
          </Box>
        </Box>
        <Box>
          <Box p="20px" bg="#f4fae3" minW="200px" borderRadius="md">
            <Flex mb="30px" alignItems="baseline">
              <Text as="span" fontWeight="bold" color="green" fontSize="2xl">
                {store.item.fullConvertedPrice} &#8381;
              </Text>
              <Text
                flex={['0', '0', '1']}
                fontWeight="bold"
                color="gray.400"
                textAlign="center"
              >
                /
              </Text>
              <Text as="span" fontWeight="bold" color="gray">
                {store.item.count} pcs.
              </Text>
            </Flex>

            {user.cart?.items.find((item) => item.itemId === id) ? (
              <InputGroup size="sm" mx="20px">
                <InputLeftElement>
                  <Button
                    size="sm"
                    colorScheme="green"
                    onClick={() => user.addToCart(store.item.id, input.value)}
                    {...dec}
                  >
                    {input.value === 1 ? <DeleteIcon /> : '-'}
                  </Button>
                </InputLeftElement>
                <Input
                  colorScheme="green"
                  variant="filled"
                  _focusVisible={{}}
                  textAlign="center"
                  {...input}
                />
                <InputRightElement>
                  <Button
                    size="sm"
                    colorScheme="green"
                    onClick={() => user.addToCart(store.item.id, input.value)}
                    {...inc}
                  >
                    +
                  </Button>
                </InputRightElement>
              </InputGroup>
            ) : (
              <Button
                w="full"
                colorScheme="green"
                isLoading={user.loading}
                onClick={addHandler}
              >
                В корзину
              </Button>
            )}
          </Box>
          <Box mt="10px" color="blue" textAlign="center">
            <Link as={rLink} to={`/catalog/v/${store.item.vid}`} fontSize="xs">
              Все товары продавца
            </Link>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
});

export default ItemViewPage;
