import $api from '../http';

export default class ItemService {
  static async getItems(params) {
    return $api.get(`/catalog/items/`, { params });
  }

  static async search(query, params = {}) {
    return $api.get(`/catalog/search/${query}`, { params });
  }

  static async getItemsCat(cat, subcat, params) {
    return $api.get(`/catalog/category/${cat}/` + subcat, { params });
  }

  static async getItem(id) {
    return $api.get(`/catalog/item/${id}`);
  }

  static async getCategories(fill = null) {
    return $api.get('/catalog/category', { params: { fill } });
  }
}
