import React, { useEffect, useState } from 'react';
import { Link as rLink } from 'react-router-dom';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  HStack,
  Image,
  Input,
  Text,
  useNumberInput,
  Icon,
  VStack,
  Spacer,
  InputGroup,
  InputRightElement,
  InputLeftElement,
  IconButton,
  Link,
} from '@chakra-ui/react';
import { BiTrash } from 'react-icons/bi';
import { observer } from 'mobx-react-lite';
import { useStore } from '../hooks/useStore';

const CatCard = observer(({ item }) => {
  const { user } = useStore();

  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
    useNumberInput({
      step: 1,
      defaultValue: item.cartCount,
      min: 1,
    });
  const inc = getIncrementButtonProps();
  const dec = getDecrementButtonProps();
  const input = getInputProps();

  return (
    <Box mb="30px">
      <Flex
        flexDirection={['column', 'column', 'row']}
        gap={['10px', '10px', '20px']}
      >
        <Flex flex="1">
          <Box w="100px" h="100px" bg="gray.300">
            {!!item.images.length && (
              <Link as={rLink} to={`/item/${item.itemId}`}>
                <Image boxSize="100px" src={item.images[0].link} />
              </Link>
            )}
          </Box>

          <Box flex="1" mx="20px">
            <Text>{item.name}</Text>

            <Flex flexDirection={['column', 'column', 'row']}>
              <HStack fontSize="sm" mt="10px">
                <Text bg="gray.100" px="10px" py="2px" borderRadius="4px">
                  Размер - {item.size}
                </Text>
                <Text bg="gray.100" px="10px" py="2px" borderRadius="4px">
                  Цвет - {item.color}
                </Text>
              </HStack>
            </Flex>
          </Box>
        </Flex>

        <Flex gap={['10px', '10px', '20px']} justifyContent="space-between">
          <Box fontSize="sm" color="gray.500">
            <Box>
              Упаковка:
              <Text>
                {item.pkg_x} x {item.pkg_y} x {item.pkg_z} (cm)
              </Text>
            </Box>
            <Text>Вес: {item.pkg_w} kg</Text>
          </Box>

          <Box>
            <VStack w="150px" h="full">
              <Box textAlign="left">
                <Text as="span" color="#9DC41A" fontSize="2xl">
                  {item.fullConvertedPrice} &#8381;
                </Text>{' '}
                / {item.count} шт
              </Box>

              <Spacer h="full" />

              <InputGroup size="sm" mx="20px">
                <InputLeftElement>
                  <Button
                    size="sm"
                    onClick={() => user.addToCart(item.itemId, input.value)}
                    {...dec}
                  >
                    -
                  </Button>
                </InputLeftElement>
                <Input
                  variant="filled"
                  _focusVisible={{}}
                  textAlign="center"
                  {...input}
                />
                <InputRightElement>
                  <Button
                    size="sm"
                    onClick={() => user.addToCart(item.itemId, input.value)}
                    {...inc}
                  >
                    +
                  </Button>
                </InputRightElement>
              </InputGroup>
            </VStack>
          </Box>

          <Box alignSelf="end">
            <IconButton
              onClick={() => user.removeFromCart(item.itemId)}
              mb="0"
              icon={<Icon as={BiTrash} />}
            />
          </Box>
        </Flex>
      </Flex>

      <Divider mt="10px" />
    </Box>
  );
});

export default CatCard;

// <Box
//       as={rLink}
//       to={`/item/${item.id}`}
//       h="200px"
//       borderRadius="10px"
//       bg="gray.200"
//     >
//       <Heading>{item.name}</Heading>
//     </Box>
