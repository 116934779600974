import React from 'react';
import {
  Center,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  PinInput,
  PinInputField,
  Spinner,
  useDisclosure,
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../hooks/useStore';

const SmsForm = ({ registerStore }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { user } = useStore();

  return (
    <Modal isOpen={registerStore.isEnterCode} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign="center">Введите код из SMS</ModalHeader>
        <ModalBody mb="20px">
          <Center>
            {registerStore.loading ? (
              <Spinner />
            ) : (
              <HStack>
                <PinInput
                  onComplete={async (value) => {
                    await registerStore.checkPhone(value);
                    user.checkAuth();
                  }}
                >
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                </PinInput>
              </HStack>
            )}
          </Center>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default observer(SmsForm);
