import React, { useRef, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  VStack,
  Input,
  FormControl,
  FormLabel,
  Heading,
  Divider,
  Select,
  InputGroup,
  Checkbox,
  Link,
  InputRightElement,
  Center,
} from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { Link as rLink, useNavigate } from 'react-router-dom';
import useInput from '../hooks/useInput';
import { observer } from 'mobx-react-lite';
import SmsForm from '../components/SmsForm';
import useMessage from '../hooks/useMessage';
import useValidation from '../hooks/useValidation';
import { useStore } from '../hooks/useStore';

const RegisterPage = () => {
  const { register, user } = useStore();
  const navigate = useNavigate();
  const email = useValidation(register.email, {
    email: true,
    isEmpty: true,
  });
  const phone = useValidation(register.phone, { isEmpty: true });
  const password = useValidation(register.password, { isEmpty: true });
  const password2 = useInput('', {
    isEmpty: true,
    compare: register.password,
  });

  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  const [policy, setPolicy] = useState(false);
  const policyValidation = useValidation(policy, { isEmpty: true });

  const inputCard = useRef();

  const handleChange = () => {
    const cardValue = inputCard.current.value
      .replace(/\D/g, '')
      .match(/(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/);
    inputCard.current.value = !cardValue[2]
      ? cardValue[1]
      : `${cardValue[1]}-${cardValue[2]}${`${
          cardValue[3] ? `-${cardValue[3]}` : ''
        }`}${`${cardValue[4] ? `-${cardValue[4]}` : ''}`}`;
    const numbers = inputCard.current.value.replace(/(\D)/g, '');
    register.setPhone(numbers);
  };

  const registerHandler = async () => {
    await register.register();
    navigate('/inactive');
  };

  useMessage(register.error, () => register.setError(null));

  return (
    <>
      <Flex direction="column" mt="100px" align="center">
        <Box w="90%" maxW="500px" m="auto">
          <Box bg="white" borderRadius="md" p="30px" boxShadow="lg">
            <VStack spacing="20px">
              <Box>
                <Heading size="md">Регистрация</Heading>
              </Box>
              <Divider />
              <FormControl>
                <FormLabel>E-mail</FormLabel>
                <Input
                  type="text"
                  name="email"
                  placeholder="E-mail"
                  value={register.email}
                  onChange={(e) => register.setEmail(e.target.value)}
                  isDisabled={register.loading}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Номер телефона</FormLabel>
                <InputGroup>
                  <Select
                    onChange={(e) => register.setCode(e.target.value)}
                    name="code"
                    w="100px"
                    mr="10px"
                  >
                    <option value="+7">+7</option>
                    <option value="+90">+90</option>
                  </Select>
                  <Input
                    ref={inputCard}
                    type="text"
                    name="phone"
                    placeholder="Введите номер телефона"
                    onChange={handleChange}
                    isDisabled={register.loading}
                  />
                </InputGroup>
              </FormControl>
              <FormControl>
                <FormLabel>Пароль</FormLabel>
                <InputGroup>
                  <Input
                    type={show ? 'text' : 'password'}
                    name="password"
                    placeholder="Введите пароль"
                    value={register.password}
                    onChange={(e) => register.setPassword(e.target.value)}
                    isDisabled={register.loading}
                  />
                  <InputRightElement>
                    <Button
                      variant="ghost"
                      color="gray.600"
                      size="sm"
                      onClick={handleClick}
                    >
                      {show ? <ViewIcon /> : <ViewOffIcon />}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              <FormControl>
                <FormLabel>Подтвердите пароль</FormLabel>
                <InputGroup>
                  <Input
                    type={show ? 'text' : 'password'}
                    name="password2"
                    placeholder="Подтвердите пароль"
                    {...password2.props}
                    isDisabled={register.loading}
                  />
                  <InputRightElement>
                    <Button
                      variant="ghost"
                      color="gray.600"
                      size="sm"
                      onClick={handleClick}
                    >
                      {show ? <ViewIcon /> : <ViewOffIcon />}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              <Checkbox
                colorScheme="green"
                onChange={(e) => setPolicy(e.target.checked)}
              >
                Я согласен с{' '}
                <Link as={rLink} to="/policy" color="green" fontWeight="medium">
                  политикой конфиденциальности
                </Link>
              </Checkbox>
              <Button
                colorScheme="green"
                px="40px"
                onClick={registerHandler}
                isLoading={register.loading}
                isDisabled={
                  email.err ||
                  password.err ||
                  phone.err ||
                  password2.err ||
                  policyValidation.err
                }
              >
                Зарегистрироваться
              </Button>
              <Button
                as={rLink}
                size="xs"
                variant="ghost"
                color="blackAlpha.700"
                _hover={{ color: 'black' }}
                w="full"
                to="/login"
              >
                Авторизоваться
              </Button>
            </VStack>
          </Box>
        </Box>
      </Flex>
    </>
  );
};

export default observer(RegisterPage);
