import React, { useEffect, useRef, useState } from 'react';
import {
  Center,
  Flex,
  Grid,
  Heading,
  Spinner,
  Box,
  Text,
} from '@chakra-ui/react';
import BannerSmall from '../components/BannerSmall';
import ItemCard from '../components/ItemCard';
import Pagination from '../components/Pagination';
import { observer } from 'mobx-react-lite';
import { useStore } from '../hooks/useStore';
import { useParams, Link as rLink } from 'react-router-dom';

const CategoryItemsPage = observer(() => {
  const catId = Number(useParams().catId);
  const subcatId = Number(useParams().subcatId) || '';

  const [subcat, setSubcat] = useState(null);
  const [items, setItems] = useState([]);

  const { store } = useStore();

  useEffect(() => {
    return () => {
      store.setItems([]);
    };
  }, []);

  useEffect(() => {
    setItems([]);
    store.getItemsCat(catId, subcatId);
  }, [catId, subcatId]);

  useEffect(() => {
    setItems(store.items);
    // setItems([...items, ...store.items]);
  }, [store.items]);

  useEffect(() => {
    if (subcatId) {
      const findSubcat = store.category.subcats.find(
        (item) => item.id == subcatId
      );
      setSubcat(findSubcat);
    }
  }, [store.category]);

  return (
    <>
      {!subcatId ? (
        <>
          <BannerSmall title={store.category.name} img={store.category.img} />
          <Flex
            mb={['20px', '20px', '50px']}
            gap={['10px', '10px', '20px', '30px']}
            flexWrap="wrap"
          >
            {store.category.subcats.map((item) => (
              <Box
                key={item.id}
                as={rLink}
                to={`/catalog/${catId}/${item.id}`}
                bg="blackAlpha.200"
                bgGradient="radial-gradient(100% 77.31% at 0% 100%, rgba(200, 23, 66, 0.8) 0%, rgba(252, 195, 48, 0.8) 48.96%, rgba(216, 216, 216, 0) 100%)"
                borderRadius="4px"
                px={['10px', '10px', '15px', '20px']}
                py={['5px', '5px', '8px', '10px']}
              >
                <Text
                  fontSize="20px"
                  fontWeight="medium"
                  color="blackAlpha.800"
                >
                  {item?.name}
                </Text>
              </Box>
            ))}
          </Flex>
        </>
      ) : (
        <>
          <BannerSmall
            title={store.category.name}
            to={`/catalog/${catId}`}
            img={store.category.img}
          />
          <Heading mb="50px" fontWeight="medium" color="gray.400">
            {subcat?.name}
          </Heading>
        </>
      )}
      {!store.loading ? (
        <Grid
          templateColumns={[
            'repeat(2, 1fr)',
            'repeat(2, 1fr)',
            'repeat(4, 1fr)',
            'repeat(5, 1fr)',
          ]}
          gap={8}
        >
          {items.length || store.loading
            ? items.map((card) => <ItemCard key={card.id} item={card} />)
            : !!store.count || 'No items'}
        </Grid>
      ) : (
        <Center mt="30px">
          <Spinner />
        </Center>
      )}
      <Pagination
        isLoading={store.loading}
        itemsCount={store.count}
        callback={(...args) => store.getItemsCat(catId, subcatId, ...args)}
      />
    </>
  );
});

export default CategoryItemsPage;
