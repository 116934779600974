import React, { useState, useEffect } from 'react';
import {
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  PopoverFooter,
  Textarea,
} from '@chakra-ui/react';
import { useStore } from '../hooks/useStore';

function WrongForm({ order, item, onWrong }) {
  const { user } = useStore();

  const [comment, setComment] = useState('');
  const [wrong, setWrong] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    onWrong(wrong);
  }, [wrong]);

  const openWrongForm = (id) => {
    if (wrong) {
      user.removeWrongItem(order.id, id);
      setWrong(false);
      setComment('');
    } else {
      setWrong(!wrong);
      setOpen(!open);
    }
  };

  const saveWrong = () => {
    user.setWrongItem(order.id, item.id, comment);
    setOpen(false);
  };

  const closeWrong = () => {
    if (comment) setComment('');
    if (wrong) setWrong(false);
    setOpen(false);
  };

  return (
    <>
      <Popover
        returnFocusOnClose={false}
        closeOnBlur={false}
        isOpen={open}
        onClose={closeWrong}
      >
        <PopoverTrigger>
          <Button
            onClick={() => openWrongForm(item.id)}
            size="xs"
            colorScheme={wrong ? 'orange' : 'gray'}
            h="full"
          >
            Не соответствует
          </Button>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverHeader fontWeight="semibold">
            Опишите несоответствие
          </PopoverHeader>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverBody>
            <Textarea
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder="Опишите что не соответствует"
              size="sm"
            />
          </PopoverBody>
          <PopoverFooter display="flex" justifyContent="flex-end">
            <Button colorScheme="red" onClick={saveWrong}>
              Сохранить
            </Button>
          </PopoverFooter>
        </PopoverContent>
      </Popover>
    </>
  );
}
export default WrongForm;
