import {
  Avatar,
  Box,
  Text,
  Flex,
  FormControl,
  Input,
  FormLabel,
  Button,
  Center,
  Badge,
  Tag,
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useStore } from '../hooks/useStore';
import UserService from '../service/UserService';
import FileUpload from '../components/FileUpload';

const colors = {
  active: 'green',
  blocked: 'red',
};

const UserPage = () => {
  const { user } = useStore();

  const [usr, setUser] = useState({
    avatar: user.userData.avatar,
    email: user.userData.email,
    login: user.userData.login || '',
    name: user.userData.name || '',
    phone: user.userData.phone,
    second_name: user.userData.second_name || '',
  });

  const [check, setCheck] = useState({ email: false, login: false });

  const [avatar, setAvatar] = useState(null);

  const fileHendler = (e) => {
    const img = e.target.files[0];
    setUser({ ...usr, avatar: img });
    const image = URL.createObjectURL(img);
    setAvatar(image);
  };

  const checkField = async (e) => {
    const { data } = await UserService.checkField({
      [e.target.name]: e.target.value,
    });
    setCheck({ ...check, [e.target.name]: data.find });
  };

  const sendHandler = async () => {
    user.updateUser(usr);
  };

  return (
    <Flex
      gap="20px"
      flexDirection={['column', 'column', 'row']}
      alignItems={['stretch', 'stretch', 'start']}
    >
      <Flex
        bg="white"
        gap="20px"
        borderRadius="md"
        p="20px"
        align="center"
        minW={['auto', 'auto', '200px']}
        flexDirection={['row', 'row', 'column']}
      >
        <Avatar size="lg" src={avatar || usr.avatar} />
        <Box textAlign={['left', 'left', 'center']}>
          <Box>
            <Text as="b">{usr.login || usr.email}</Text>
          </Box>
          <Box>
            Статус:{' '}
            <Tag borderRadius="full" colorScheme={colors[user.userData.status]}>
              {user.userData.status}
            </Tag>
          </Box>
        </Box>
      </Flex>
      <Flex
        bg="white"
        borderRadius="md"
        p={['15px', '15px', '40px']}
        flex="1"
        flexDirection={['column', 'column', 'row']}
      >
        <Box textAlign={['center']}>
          <Avatar
            boxSize={['100px', '150px', '200px']}
            src={avatar || usr.avatar}
            mb="30px"
          />
          <FileUpload accept={'image/*'} hendler={fileHendler}>
            <Button>Загрузить изображение</Button>
          </FileUpload>
        </Box>
        <Box ml={['0', '0', '40px']} flex="1">
          <FormControl mb="20px">
            <FormLabel>Логин</FormLabel>
            <Input
              type="text"
              name="login"
              bg={check.login ? 'red.100' : ''}
              errorBorderColor="red.300"
              value={usr.login}
              onChange={(e) => {
                checkField(e);
                setUser({ ...usr, [e.target.name]: e.target.value });
              }}
              isDisabled={user.loading}
            />
          </FormControl>
          <FormControl mb="20px">
            <FormLabel>Имя</FormLabel>
            <Input
              type="text"
              name="name"
              value={usr.name}
              onChange={(e) => {
                setUser({ ...usr, [e.target.name]: e.target.value });
              }}
              isDisabled={user.loading}
            />
          </FormControl>
          <FormControl mb="20px">
            <FormLabel>Фамилия</FormLabel>
            <Input
              type="text"
              name="second_name"
              value={usr.second_name}
              onChange={(e) =>
                setUser({ ...usr, [e.target.name]: e.target.value })
              }
              isDisabled={user.loading}
            />
          </FormControl>
          <FormControl mb="20px">
            <FormLabel>E-mail</FormLabel>
            <Input
              type="text"
              name="email"
              bg={check.email ? 'red.100' : ''}
              errorBorderColor="red.300"
              value={usr.email}
              onChange={(e) => {
                checkField(e);
                setUser({ ...usr, [e.target.name]: e.target.value });
              }}
              isDisabled={user.loading}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Номер телефона</FormLabel>
            <Input
              type="text"
              name="phone"
              value={usr.phone}
              onChange={(e) =>
                setUser({ ...usr, [e.target.name]: e.target.value })
              }
              isDisabled={user.loading}
            />
          </FormControl>
        </Box>
        <Button
          ml={['auto', 'auto', '40px']}
          mt={['20px', '20px', '0']}
          w={['full', 'full', 'auto']}
          colorScheme="green"
          onClick={sendHandler}
          isLoading={user.loading}
        >
          Сохранить
        </Button>
      </Flex>
    </Flex>
  );
};

export default observer(UserPage);
