import { Box } from '@chakra-ui/react';
import React from 'react';
import { useStore } from '../hooks/useStore';
import { Navigate } from 'react-router-dom';

export default function WarningPage() {
  const { user } = useStore();

  if (user?.userData?.status === 'active') {
    return <Navigate to="/" />;
  }
  return (
    <Box>
      Активируйте аккаунт по ссылке отправленной на ваш E-mail, указанный при
      регистрации
    </Box>
  );
}
