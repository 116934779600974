import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Box,
  Button,
  Flex,
  VStack,
  Input,
  FormControl,
  FormLabel,
  Heading,
  Divider,
  Link,
  InputGroup,
  InputRightElement,
  Text,
  Spacer,
  Center,
} from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { Link as rLink } from 'react-router-dom';
import useInput from '../hooks/useInput';
import { useStore } from '../hooks/useStore';
import useMessage from '../hooks/useMessage';
import Social from '../components/Social';

const Login = () => {
  const { user } = useStore();

  const login = useInput('', { isEmpty: true });
  const password = useInput('', { isEmpty: true });

  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  const authHandler = () => {
    user.login(login.props.value, password.props.value);
  };

  useMessage(user.error, () => user.setError(null));

  return (
    <>
      <Flex direction="column" mt="100px" align="center">
        <Box w="90%" maxW="500px" m="auto">
          <Box bg="white" borderRadius="md" p="30px" boxShadow="lg">
            <VStack spacing="20px">
              <Box>
                <Heading size="md">Авторизация</Heading>
              </Box>
              <Divider />
              <FormControl>
                <FormLabel>Логин</FormLabel>
                <Input
                  placeholder="E-mail, номер телефона или логин"
                  name="login"
                  {...login.props}
                  isDisabled={user.loading}
                />
              </FormControl>
              <FormControl>
                <FormLabel>
                  <Flex alignItems="baseline">
                    <Text>Пароль</Text>
                    <Spacer />
                    <Link fontSize="xs" color="gray.400">
                      Забыли пароль?
                    </Link>
                  </Flex>
                </FormLabel>
                <InputGroup>
                  <Input
                    type={show ? 'text' : 'password'}
                    name="password"
                    placeholder="Enter password"
                    {...password.props}
                    isDisabled={user.loading}
                  />
                  <InputRightElement>
                    <Button
                      variant="ghost"
                      color="gray.600"
                      size="sm"
                      onClick={handleClick}
                    >
                      {show ? <ViewIcon /> : <ViewOffIcon />}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>

              <Button
                colorScheme="green"
                px="40px"
                onClick={authHandler}
                isLoading={user.loading}
                isDisabled={login.err || password.err}
              >
                Войти
              </Button>

              <Button
                as={rLink}
                size="xs"
                variant="ghost"
                color="blackAlpha.700"
                _hover={{ color: 'black' }}
                w="full"
                to="/register"
              >
                Зарегистрироваться
              </Button>
            </VStack>
          </Box>
        </Box>
      </Flex>
    </>
  );
};

export default observer(Login);
