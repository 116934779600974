import React, { useState, useRef } from 'react';
import {
  Box,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import { Search2Icon } from '@chakra-ui/icons';
import { useStore } from '../hooks/useStore';
import { observer } from 'mobx-react-lite';
import { Link as rLink, useNavigate } from 'react-router-dom';

const Search = () => {
  const { store } = useStore();

  const goTo = useNavigate();

  const searchForm = useRef();
  const enterKey = (e) => {
    if (e.key === 'Enter') {
      searchForm.current.blur();
      // store.searchItems();
      goTo(`/search/${store.search}`);
    }
  };

  return (
    <Box flex="1" mx={['0', '0', '50px']}>
      <InputGroup>
        <Input
          variant="outline"
          color="white"
          _hover={{ bg: 'whiteAlpha.500' }}
          _focus={{ bg: 'whiteAlpha.900', color: 'black' }}
          _focusVisible={{}}
          borderRadius="full"
          borderWidth="2px"
          borderColor="white"
          value={store.search}
          onChange={(e) => store.setSearch(e.target.value)}
          onKeyDown={enterKey}
          ref={searchForm}
        />
        <InputRightElement>
          <IconButton
            as={rLink}
            to={`/search/${store.search}`}
            // onClick={() => store.searchItems()}
            borderRadius="full"
            bg="white"
            icon={<Search2Icon />}
          />
        </InputRightElement>
      </InputGroup>
    </Box>
  );
};

export default observer(Search);
