import React, { useState } from 'react';
import { Box, Flex, Image, Text, Button } from '@chakra-ui/react';
import { Link as rLink } from 'react-router-dom';
import StockImages from './StockImages';
import { useStore } from '../hooks/useStore';
import WrongForm from './WrongForm';
import { observer } from 'mobx-react-lite';

export const orderItemTag = {
  'wait confirm': ['gray.200', 'Ждет подтверждения'],
  modified: ['orange.200', 'Изменен'],
  checked: ['green.200', 'Подтвержден'],
  wrong: ['red.200', 'Несоответствует'],
  deleted: ['red.200', 'Удален'],
  send: ['teal.200', 'Отправлен'],
  recive: ['cyan.200', 'На складе'],
  updated: ['green.200', 'Обновлен'],
  mismatch: ['orange.200', 'Несоответствие на складе'],
};

const OrderItemCard = ({ item, order }) => {
  const { user } = useStore();
  const [photos, setPhotos] = useState(false);
  const [wrong, setWrong] = useState(false);

  return (
    <Flex
      gap={['10px', '10px', '20px']}
      mb="20px"
      bg={item.status === 'modified' ? 'orange.100' : wrong && 'orange.100'}
      py="5px"
      pl={['5px', '5px', '0']}
      pr="5px"
      borderRightRadius="md"
      borderLeftRadius={['md', 'md', '0']}
      flexDir={['column', 'column', 'row']}
    >
      <Flex gap={['5px', '10px', '20px']} flexDir={['column', 'column', 'row']}>
        {!['send', 'delivered', 'issued', 'assembled'].includes(
          order.status
        ) && (
          <Flex
            bg={orderItemTag[item.status][0]}
            fontSize="xs"
            borderRightRadius="md"
            borderLeftRadius={['md', 'md', '0']}
            px="10px"
            alignItems="center"
          >
            {orderItemTag[item.status][1]}
          </Flex>
        )}
        <Flex
          gap={['10px', '10px', '20px']}
          flexDir={['column', 'column', 'row']}
        >
          <Flex gap="20px">
            <Box>
              {!!item.images.length && (
                <Image
                  objectFit="cover"
                  boxSize="50px"
                  src={item.images[0].link}
                />
              )}
            </Box>
            <Box>
              <Box as={rLink} to={`/item/${item.itemId}`}>
                {item.name}
              </Box>
              <Box fontSize="12px">
                Упаковка: {item.pkg_x} x {item.pkg_y} x {item.pkg_z} Вес:{' '}
                {item.pkg_w}
              </Box>
            </Box>
          </Flex>

          <Box>
            <Flex flexDir={['row', 'row', 'column']} gap="10px">
              <Text fontSize="xs">
                Колличество в заказе - {item.orderCount}
              </Text>
              {item.factCount === 0 ? (
                <Text>Нет в наличии</Text>
              ) : (
                item.factCount &&
                item.factCount < item.orderCount && (
                  <Text color="red" fontWeight="bold" fontSize="xs">
                    В наличии - {item.factCount}
                  </Text>
                )
              )}

              {item.stockCount && (
                <Text fontSize="xs">
                  Поступило на склад - {item.stockCount}
                </Text>
              )}
            </Flex>
          </Box>
        </Flex>
      </Flex>

      <Flex
        flex="1"
        gap="20px"
        justifyContent={['space-between', 'flex-end', 'space-between']}
      >
        <Box>
          {item.stockDate && (
            <>
              <Button onClick={() => setPhotos(true)} size="xs" h="full">
                Фото со склада
              </Button>
              <StockImages
                orderItem={item.id}
                isOpen={photos}
                onClose={() => setPhotos(false)}
              />
            </>
          )}
        </Box>

        <Box>
          {item.status === 'updated' && (
            <Button
              mr="10px"
              colorScheme="green"
              size="xs"
              h="full"
              onClick={() => user.checkItem(item.id)}
              isLoading={user.loading}
            >
              Подтвердить
            </Button>
          )}
          {order.status === 'stock' &&
            ['recive', 'updated'].includes(item.status) &&
            !['send', 'delivered', 'issued', 'assembled', 'confirm'].includes(
              order.status
            ) && <WrongForm order={order} item={item} onWrong={setWrong} />}
        </Box>
      </Flex>
    </Flex>
  );
};

export default observer(OrderItemCard);
