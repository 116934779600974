import React, { useEffect, useState } from 'react';
import { Box, Select, Text } from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../hooks/useStore';

const StockSelect = () => {
  const { user } = useStore();

  useEffect(() => {
    user.getStock();
  }, []);

  return (
    <>
      <Box>
        <Text fontSize="xs">Выберете пункт выдачи в России</Text>
        <Select size="xs" onChange={(e) => user.selectStock(e.target.value)}>
          {user.stock.map((item) => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
        </Select>
        <Box>{user.selectedStock?.address}</Box>
      </Box>
    </>
  );
};

export default observer(StockSelect);
