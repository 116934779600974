import $api from '../http';

export default class OrderService {
  static async getCart() {
    return $api.get(`/orders/cart`);
  }

  static async getLinkCart() {
    return $api.get(`/orders/linkcart`);
  }

  static async addToCart(params) {
    return $api.post(`/orders/cart`, { ...params });
  }

  static async removeFromCart(item) {
    return $api.delete(`/orders/cart`, { data: { item } });
  }

  static async addLink(params) {
    return $api.post(`/orders/linkcart/link`, { ...params });
  }

  static async removeLink(link) {
    return $api.delete(`/orders/linkcart/link`, { data: { link } });
  }

  static async setOrder(data, link = false) {
    if (link) {
      return $api.post(`/orders?q=link`, data);
    }
    return $api.post(`/orders`, data);
  }

  static async checkItem(id) {
    return $api.put(`/orders/checkitem/${id}`);
  }
  static async sendTicket(id, data) {
    return $api.put(`/orders/ticket/${id}`, data);
  }
  static async confirm(id) {
    return $api.put(`/orders/confirm/${id}`);
  }
  static async wrong(id, data) {
    return $api.put(`/orders/wrong/${id}`, data);
  }
  static async update(id, data = {}) {
    return $api.put(`/orders/update/${id}`, data);
  }
  static async trash(id) {
    return $api.put(`/orders/trash/${id}`);
  }
  static async delete(id) {
    return $api.delete(`/orders/delete/${id}`);
  }
  static async restore(id) {
    return $api.put(`/orders/restore/${id}`);
  }

  static async getOrders() {
    return $api.get(`/orders`);
  }

  static async getOrder(id) {
    return $api.get(`/orders/${id}`); //Обработать запрос
  }

  static async get() {
    return $api.get('/catalog/category');
  }

  static async getStock() {
    return $api.get('/orders/stock');
  }

  static async getStockImages(id) {
    return $api.get(`/orders/stock/images/${id}`);
  }
}
