import React, { useEffect, useRef, useState } from 'react';
import { Center, Grid, Heading, Spinner, Box, Text } from '@chakra-ui/react';
import Banner from '../components/Banner';
import ItemCard from '../components/ItemCard';
import Pagination from '../components/Pagination';
import { observer } from 'mobx-react-lite';
import { useStore } from '../hooks/useStore';
import { useParams } from 'react-router-dom';

const Search = observer(() => {
  const { store } = useStore();
  const { string } = useParams();

  const [searchText, setSearchText] = useState(null);

  useEffect(() => {
    return () => {
      store.setSearch('');
      store.setItems([]);
    };
  }, []);

  useEffect(() => {
    if (searchText) store.searchItems();
    setSearchText(string);
  }, [string]);

  return (
    <>
      {!store.loading ? (
        <>
          <Heading size="sm" mb="30px" fontWeight="light" color="gray.600">
            {searchText && store.count ? (
              <Box>
                По запросу{' '}
                <Text as="b" fontSize="xl">
                  {string}
                </Text>{' '}
                найдено <Text as="b">{store.count}</Text> товаров
              </Box>
            ) : (
              <Box>
                По запросу <Text as="b">{string}</Text> ничего не найдено
              </Box>
            )}
          </Heading>

          <Grid
            templateColumns={[
              'repeat(2, 1fr)',
              'repeat(2, 1fr)',
              'repeat(4, 1fr)',
              'repeat(5, 1fr)',
            ]}
            gap={['15px', '20px', '30px']}
            alignContent="stretch"
          >
            {store.items.map((card) => (
              <ItemCard key={card.id} item={card} />
            ))}
          </Grid>
        </>
      ) : (
        <Center mt="30px">
          <Spinner />
        </Center>
      )}
      <Pagination
        itemsCount={store.count}
        isLoading={store.loading}
        callback={(...args) => store.searchItems(...args)}
      />
    </>
  );
});

export default Search;
