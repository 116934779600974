import React, { createContext } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter } from 'react-router-dom';
import { main } from './themes';
import { StoreContext } from './hooks/useStore';
import MainStore from './store';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <StoreContext.Provider value={new MainStore()}>
    <ChakraProvider theme={main}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ChakraProvider>
  </StoreContext.Provider>
);
