import React, { useEffect } from 'react';
import {
  Box,
  Heading,
  Text,
  HStack,
  Spacer,
  Button,
  Center,
  Flex,
  Spinner,
  Divider,
} from '@chakra-ui/react';
import { InfoIcon } from '@chakra-ui/icons';
import StockSelect from '../components/StockSelect';
import LinkCartForm from '../components/LinkCartForm';
import LinkCartItem from '../components/LinkCartItem';
import { useStore } from '../hooks/useStore';
import { observer } from 'mobx-react-lite';
import { Navigate, useNavigate } from 'react-router-dom';

const LinkCartPage = () => {
  const { user } = useStore();
  const navigate = useNavigate();

  const orderHandler = async () => {
    await user.setOrder(true);
    navigate('/orders');
  };

  useEffect(() => {
    if (user?.userData?.status === 'active') {
      if (!user.linkCart?.length) user.getLinkCart();
    }
  }, []);

  if (user?.userData?.status === 'active') {
    return (
      <Box>
        <Heading mb="20px" fontWeight="medium" color="gray.600">
          Заказать товар по ссылке
        </Heading>
        <Box my="30px">
          Вы можете оформить товар из других магазинов просто прикрепив ссылку
          на этот товар и указав цвет, размер и другие характеристики а также
          необходимое колличество товара. После того как вы нажмете на кнопку
          "Оформить заказ" наши менеджеры уточнят цену и всю необходимую
          информацию.
          <Text p="10px" bg="gray.100" borderRadius="md" mt="20px">
            <InfoIcon mr="15px" />
            Вам нужно создавать отдельную позицию на каждый товар для разного
            цвета и размера.
          </Text>
        </Box>

        <Flex
          gap="20px"
          alignItems={['stretch', 'stretch', 'start']}
          flexDirection={['column', 'column', 'row']}
        >
          <Box w="full">
            {user.linkCart?.linkItems.map((el) => (
              <LinkCartItem key={el.id} item={el} />
            ))}
            <Divider mt="10px" />
            <LinkCartForm />
          </Box>
          <Box w={['auto', 'auto', '300px']} p="20px" bg="gray.50">
            {user.linkCart && (
              <>
                <StockSelect />
                <Center mt="20px">
                  <Button
                    colorScheme="green"
                    isLoading={user.loading}
                    onClick={orderHandler}
                  >
                    Оформить заказ
                  </Button>
                </Center>
              </>
            )}
          </Box>
        </Flex>
      </Box>
    );
  }

  return <Navigate to="/inactive" />;
};

export default observer(LinkCartPage);
