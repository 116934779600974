import React, { useEffect } from 'react';
import {
  Box,
  Button,
  Divider,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import { Link as rLink } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStore } from '../hooks/useStore';

const MainMenu = () => {
  const { store } = useStore();

  useEffect(() => {
    store.getCategories(true);
  }, []);

  return (
    <Box mr={['10px', '20px', '50px']}>
      <Menu colorScheme="blue">
        <MenuButton
          leftIcon={<HamburgerIcon />}
          as={Button}
          borderRadius="md"
          variant="ghost"
        >
          <Text display={['none', 'none', 'inline']}>Каталог</Text>
        </MenuButton>
        <MenuList>
          {/* <MenuItem as={rLink} fontWeight="medium" to="/catalog">
            Категории товаров
          </MenuItem>
          <Divider mb="20px" /> */}
          {store.categories.map((item) => (
            <MenuItem as={rLink} to={`/catalog/${item.id}`} key={item.id}>
              {item.name}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Box>
  );
};

export default observer(MainMenu);
