import { makeAutoObservable } from 'mobx';
import ItemService from '../service/ItemService';

export default class Store {
  items = [];
  item = {
    name: '',
    description: '',
    destination: '',
    images: [],
    pkg_x: '',
    pkg_y: '',
    pkg_z: '',
    pkg_w: '',
    subcat: '',
    price: '',
    count: '',
  };
  search = '';
  page = 1;
  count = 0;
  category = {
    id: '',
    img: null,
    subcats: [],
    name: null,
    name_en: null,
    name_tk: null,
  };
  loading = false;
  error = null;
  categories = [];
  message = null;

  constructor() {
    makeAutoObservable(this);
  }

  setLoading(bool) {
    this.loading = bool;
  }

  setSearch(string) {
    this.search = string;
  }

  setError(err) {
    this.error = err;
  }

  setMessage(msg) {
    this.message = msg;
  }

  setItems(newItems) {
    //this.items = [...this.items, ...newItems];
    this.items = newItems;
  }

  setItem(item) {
    this.item = item;
  }

  setCat(cats) {
    this.categories = cats;
  }

  setCategory(catObj) {
    this.category = catObj;
  }

  setCount(count) {
    this.count = count;
  }

  setPage(page) {
    this.page = page;
  }

  async getCategories(fill = null) {
    if (this.categories.length) return;
    this.setLoading(true);
    try {
      const { data } = await ItemService.getCategories(fill);
      this.setCat(data);
    } catch (e) {
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }

  async getItems(search = {}) {
    this.setLoading(true);
    try {
      const { data } = await ItemService.getItems(search);
      this.setCount(data.count);
      this.setItems(data.items);
    } catch (e) {
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }

  async getItemsCat(cat, subcat = '', params) {
    this.setLoading(true);
    try {
      const { data } = await ItemService.getItemsCat(cat, subcat, params);
      this.setCategory(data.category);
      this.setCount(data.count);
      this.setItems(data.items);
    } catch (e) {
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }

  async getItem(id) {
    // const idx = this.items.findIndex((item) => item.id === id);

    // if (idx === -1) {
    //   this.setItem(this.items[idx]);
    //   return;
    // }

    this.setLoading(true);
    try {
      const { data } = await ItemService.getItem(id);
      this.setItem(data);
    } catch (e) {
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }

  async searchItems(params) {
    if (!this.search) return;
    this.setLoading(true);
    this.setMessage(null);
    try {
      const { data } = await ItemService.search(this.search, params);
      this.setCount(data.count);
      if (data.message) {
        this.setMessage(data.message);
        return;
      }
      this.setItems(data.items);
    } catch (e) {
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }
}
