import React from 'react';
import { Button, Flex } from '@chakra-ui/react';
import { Link as rLink } from 'react-router-dom';

const EnterMenu = () => {
  return (
    <Flex h="52px" alignItems="center" justifyContent="flex-end">
      <Button
        as={rLink}
        to="/login"
        size="sm"
        borderRadius="md"
        variant="ghost"
      >
        Войти
      </Button>
    </Flex>
  );
};

export default EnterMenu;
