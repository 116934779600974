import { makeAutoObservable } from 'mobx';
import OrderService from '../service/OrderService';
import AuthService from '../service/AuthService';
import UserService from '../service/UserService';

export default class User {
  userData = {};
  cart = null;
  linkCart = null;
  orders = [];
  order = null;
  loading = false;
  error = null;
  isAuth = false;
  message = null;
  stock = [];
  selectedStock = {};
  stockImages = [];

  constructor() {
    makeAutoObservable(this);
  }

  setMessage(msg) {
    this.message = msg;
    setTimeout(() => {
      this.setMessage(null);
    }, 3000);
  }

  setUserData(data) {
    this.userData = data;
  }

  setCart(cart) {
    this.cart = cart;
  }
  setLinkCart(cart) {
    this.linkCart = cart;
  }

  addCart(item) {
    const prepareItem = { itemId: item.id, ...item };
    if (!this.cart) {
      this.cart = { items: [], linkItems: [] };
    }
    const idx = this.cart.items.findIndex((el) => el.itemId === item.id);
    if (idx >= 0) {
      this.cart.items[idx] = { ...this.cart.items[idx], ...prepareItem };
    } else {
      this.cart.items.push(prepareItem);
    }
  }

  remFromCart(itemId) {
    this.cart.items = this.cart.items.filter((item) => item.itemId !== itemId);
  }

  setLoading(bool) {
    this.loading = bool;
  }

  setError(error) {
    this.error = error;
  }

  setOrders(orders) {
    this.orders = orders;
  }

  selectOrder(ord) {
    this.order = ord;
  }

  setOrderStatus(order) {
    const idx = this.orders.findIndex((item) => item.id === order.id);
    this.orders[idx] = order;
  }

  setWrongItem(orderId, itemId, comment) {
    const idx = this.orders.findIndex((item) => item.id === orderId);
    if (!this.orders[idx]?.wrong && !this.orders[idx]?.wrong?.length) {
      this.orders[idx].wrong = [];
    }
    this.orders[idx].wrong.push({ item: itemId, comment });
  }
  removeWrongItem(orderId, itemId) {
    const idx = this.orders.findIndex((item) => item.id === orderId);
    if (this.orders[idx].wrong && this.orders[idx]?.wrong.length) {
      const filter = this.orders[idx].wrong.filter(
        (item) => item.item !== itemId
      );
      this.orders[idx].wrong = filter;
    }
  }

  setAuth(bool) {
    this.isAuth = bool;
  }

  setNewLink(linkObj) {
    if (!this.linkCart) {
      this.linkCart = {};
      this.linkCart.linkItems = [];
    }
    this.linkCart.linkItems.push(linkObj);
  }
  remLink(id) {
    this.linkCart.linkItems = this.linkCart.linkItems.filter(
      (item) => item.id !== id
    );
  }

  setStock(stocks) {
    this.stock = stocks;
  }
  selectStock(id) {
    const select = this.stock.find((item) => item.id === id);
    this.selectedStock = select;
  }
  setDefaultStock(stocksArr) {
    this.selectedStock = stocksArr[0];
  }

  setStockImages(images = []) {
    this.stockImages = images;
  }

  // get cartSumm() {
  //   if (this.cart) {
  //     let summ = 0;
  //     this.cart.items.forEach((it) => {
  //       summ = summ + it.cartCount * it.price;
  //     });
  //     return summ;
  //   }
  //   return 0;
  // }

  // get convertedSumm() {
  //   if (this.cart) {
  //     let summ = 0;
  //     this.cart.items.forEach((it) => {
  //       summ = summ + it.cartCount * it.fullConvertedPrice;
  //     });
  //     return summ;
  //   }
  //   return 0;
  // }

  async getCart() {
    this.setLoading(true);
    try {
      const { data } = await OrderService.getCart();
      if (data.message) return;
      this.setCart(data);
    } catch (e) {
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }

  async getLinkCart() {
    this.setLoading(true);
    try {
      const { data } = await OrderService.getLinkCart();
      if (data.message) return;
      this.setLinkCart(data);
    } catch (e) {
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }

  async addToCart(itemId, count) {
    this.setLoading(true);
    try {
      const { data } = await OrderService.addToCart({
        item: itemId,
        count,
      });
      this.setCart(data);
      // this.addCart(item);
    } catch (e) {
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }

  async removeFromCart(item) {
    this.setLoading(true);
    try {
      const { data } = await OrderService.removeFromCart(item);
      this.setCart(data);
      // this.remFromCart(item);
      this.setMessage(data.message);
    } catch (e) {
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }

  async addLink(linkObj) {
    this.setLoading(true);
    try {
      const { data } = await OrderService.addLink(linkObj);
      this.setNewLink(data.data);
    } catch (e) {
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }

  async removeLink(id) {
    this.setLoading(true);
    try {
      const { data } = await OrderService.removeLink(id);
      this.setMessage(data.message);
      this.remLink(id);
    } catch (e) {
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }

  async setOrder(link = false) {
    this.setLoading(true);
    try {
      const { data } = await OrderService.setOrder(
        {
          postId: this.selectedStock.id,
        },
        link
      );
      this.setOrders([...this.orders, data]);
      if (link) this.setLinkCart(null);
      else this.setCart(null);
    } catch (e) {
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }

  async checkItem(id) {
    this.setLoading(true);
    try {
      const { data } = await OrderService.checkItem(id);
      this.setOrderStatus(data);
    } catch (e) {
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }

  async sendTicket(id, ticket, callback = () => {}) {
    this.setLoading(true);
    try {
      const formData = new FormData();
      formData.append('ticket', ticket);

      const { data } = await OrderService.sendTicket(id, formData);
      this.setOrderStatus(data);
      callback();
    } catch (e) {
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }

  async confirmOrder(id) {
    this.setLoading(true);
    try {
      const { data } = await OrderService.confirm(id);
      this.setOrderStatus(data);
    } catch (e) {
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }

  async wrongOrder(id, wrongs) {
    this.setLoading(true);
    try {
      const { data } = await OrderService.wrong(id, {
        wrong: JSON.stringify(wrongs),
      });
      this.setOrderStatus(data);
    } catch (e) {
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }

  async updateOrder(id, deleted = {}) {
    this.setLoading(true);
    try {
      const { data } = await OrderService.update(id, {
        del: JSON.stringify(deleted),
      });
      this.setOrderStatus(data);
    } catch (e) {
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }

  async trashOrder(id) {
    this.setLoading(true);
    try {
      const { data } = await OrderService.trash(id);
      this.setOrderStatus(data);
    } catch (e) {
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }

  async restoreOrder(id) {
    this.setLoading(true);
    try {
      const { data } = await OrderService.restore(id);
      this.setOrderStatus(data);
    } catch (e) {
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }

  async deleteOrder(id) {
    this.setLoading(true);
    try {
      const { data } = await OrderService.confirm(id);
      this.setOrderStatus(id);
    } catch (e) {
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }

  async getOrders() {
    this.setLoading(true);
    try {
      const { data } = await OrderService.getOrders();
      this.setOrders(data);
    } catch (e) {
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }

  async getOrderDetails(id) {
    const order = this.orders.find((item) => item.id === id);
    if (order) {
      this.selectOrder(order);
      return;
    }
    this.setLoading(true);
    try {
      const { data } = await OrderService.getOrder(id);
      this.setOrder(data[0]);
    } catch (e) {
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }

  async login(login, password) {
    this.setLoading(true);
    try {
      const { data } = await AuthService.login(login, password);
      localStorage.setItem('tk', data.accessToken);
      this.checkAuth();
    } catch (e) {
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }

  async logout() {
    this.setLoading(true);
    try {
      await AuthService.logout();
      localStorage.removeItem('tk');
      this.setAuth(false);
      this.setUserData(null);
    } catch (e) {
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }

  async checkAuth() {
    this.setLoading(true);
    try {
      const { data } = await UserService.getCurrent();
      const { vendor, ...user } = data;
      this.setUserData(user);
      this.setAuth(true);
      this.getOrders();
      this.getCart();
    } catch (e) {
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }

  async updateUser(userData) {
    this.setLoading(true);
    try {
      const { data } = await UserService.update(userData);
      this.setUserData(data.data);
    } catch (e) {
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }

  async sendSupport(title, message) {
    this.setLoading(true);
    try {
      const { data } = await UserService.sendToSupport(title, message);
      this.setMessage(data.message);
    } catch (e) {
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }

  async getStock() {
    try {
      const { data } = await OrderService.getStock();
      this.setStock(data);
      this.setDefaultStock(data);
    } catch (e) {
      this.setError(e);
    }
  }

  async getStockImages(id) {
    try {
      const { data } = await OrderService.getStockImages(id);
      this.setStockImages(data);
    } catch (e) {
      this.setError(e);
    }
  }
}
