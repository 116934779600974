import { Box, Divider, Flex, Heading, Tag, Text } from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useStore } from '../hooks/useStore';
import { orderTag } from '../components/OrderCard';
import OrderCardBody from '../components/OrderCardBody';

const OrderViewPage = observer(() => {
  const { user } = useStore();
  const id = Number(useParams().id);

  useEffect(() => {
    user.getOrderDetails(id);
  }, []);

  return (
    <>
      <Flex justifyContent="space-between" mb="10px">
        <Flex gap="20px" alignItems="center">
          <Tag h="full" bg={orderTag[user.order.status][0]} px="20px">
            {orderTag[user.order.status][1]}
          </Tag>
          <Heading color="green" size="xl">
            {user.order.code}
          </Heading>
        </Flex>
        <Flex gap="20px">
          <Text>
            Дата заказа - {new Date(user.order.orderDate).toLocaleDateString()}
          </Text>
        </Flex>
      </Flex>
      <Divider mb="40px" />
      <Box>{user.order && <OrderCardBody order={user.order} />}</Box>
      <Divider mb="20px" />
      <Flex justifyContent="right" gap="15px">
        <Text>Сумма заказа:</Text>
        <Heading size="md" fontWeight="bold" color="green">
          {user.order.convertedSumm} &#8381;
        </Heading>
      </Flex>
    </>
  );
});

export default OrderViewPage;
