import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import CartPage from '../pages/CartPage';
import LoginPage from '../pages/LoginPage';
import OrdersPage from '../pages/OrdersPage';
import RegisterPage from '../pages/RegisterPage';
import UserPage from '../pages/UserPage';
import CatalogPage from '../pages/CatalogPage';
import MainPage from '../pages/MainPage';
import ItemViewPage from '../pages/ItemViewPage';
import OrderViewPage from '../pages/OrderViewPage';
import CategoriesPage from '../pages/CategoriesPage';
import CategoryItemsPage from '../pages/CategoryItemsPage';
import Search from '../pages/Search';
import Policy from '../pages/Policy';
import LinkCartPage from '../pages/LinkCartPage';
import VendorItemsPage from '../pages/VendorItemsPage';
import WarningPage from '../pages/WarningPage';
import ConfirmPage from '../pages/ConfirmPage';

const useRoutes = (isAuth) => {
  return (
    <Routes>
      <Route path="/" element={<MainPage />}>
        {/* <Route index element={<CatalogPage />} /> */}
        <Route index element={<Navigate to="/catalog" />} />
        <Route path="/search/:string" element={<Search />} />
        <Route path="/item/:id" element={<ItemViewPage />} />
        <Route path="/catalog" element={<CategoriesPage />} />
        <Route path="/catalog/:catId" element={<CategoryItemsPage />} />
        <Route path="/catalog/v/:vid" element={<VendorItemsPage />} />
        <Route
          path="/catalog/:catId/:subcatId"
          element={<CategoryItemsPage />}
        />
        {isAuth ? (
          <>
            <Route path="user" element={<UserPage />} />
            <Route path="linkorder" element={<LinkCartPage />} />
            <Route path="cart" element={<CartPage />} />
            <Route path="orders" element={<OrdersPage />} />
            <Route path="order/:id" element={<OrderViewPage />} />
          </>
        ) : (
          <>
            <Route path="register" element={<RegisterPage />} />
            <Route path="login" element={<LoginPage />} />
            <Route path="policy" element={<Policy />} />
            <Route path="confirm" element={<ConfirmPage />} />
          </>
        )}
        <Route path="inactive" element={<WarningPage />} />
        <Route path="*" element={<Navigate to="/catalog" />} />
      </Route>
    </Routes>
  );
};

export default useRoutes;
