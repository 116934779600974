import { AxiosError } from 'axios';
import { makeAutoObservable } from 'mobx';
import AuthService from '../service/AuthService';

const hashCode = function (s) {
  return s.split('').reduce(function (a, b) {
    a = (a << 5) - a + b.charCodeAt(0);
    return a & a;
  }, 0);
};

export default class RegisterUser {
  loading = false;
  email = '';
  phone = '';
  code = '+7';
  password = '';
  phoneVefify = false;
  smsCode = null;
  isEnterCode = false;
  error = null;

  constructor(store) {
    makeAutoObservable(this);
    this.store = store;
  }

  setEmail(email) {
    this.email = email;
  }

  setCode(code) {
    this.code = code;
  }

  setPhone(phone) {
    this.phone = phone;
  }

  setPassword(password) {
    this.password = password;
  }

  setEnterCode(bool) {
    this.isEnterCode = bool;
  }

  setError(err) {
    this.error = err;
  }

  setLoading(bool) {
    this.loading = bool;
  }

  setCode(code) {
    this.smsCode = code;
  }

  setVerify(bool) {
    this.phoneVefify = bool;
  }

  async getCheckPhone() {
    const { data } = await AuthService.getSmsCode(this.code + this.phone);
    return data.code;
  }

  async checkPhone(code) {
    this.setLoading(true);
    try {
      code = hashCode(code);
      if (code === this.smsCode) {
        this.setVerify(true);
        await this.register();
        this.setEnterCode(false);
      }
    } catch (e) {
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }

  async checkUser() {
    try {
      const { data } = await AuthService.checkUser({
        email: this.email,
        phone: this.code + this.phone,
      });
      return data?.find;
    } catch (e) {
      this.setError(e);
    }
  }

  async register() {
    this.setLoading(true);
    try {
      // if (this.phoneVefify) {
      const { data } = await AuthService.register(
        this.email,
        this.code + this.phone,
        this.password
      );
      localStorage.setItem('tk', data.accessToken);
      // } else {
      //   const checkuser = await this.checkUser();
      //   if (checkuser) {
      //     throw new AxiosError('User already exists');
      //   }
      //   const code = await this.getCheckPhone();
      //   this.setCode(code);
      //   this.setEnterCode(true);
      // }
    } catch (e) {
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }
}
