import { Flex, IconButton, Link } from '@chakra-ui/react';
import React from 'react';
import VKicon from './VKicon';
import OKicon from './OKicon';
import YTicon from './YTicon';
import TGicon from './TGicon';
import DZENicon from './DZENicon';

const Social = () => {
  return (
    <Flex mb="20px">
      <IconButton
        as={Link}
        href="https://vk.com/turkpazar_ru"
        isExternal
        size="lg"
        borderRadius="full"
        variant="ghost"
        color="blackAlpaha.300"
        icon={<VKicon />}
      />
      <IconButton
        as={Link}
        href="https://ok.ru/turkpazar"
        isExternal
        size="lg"
        borderRadius="full"
        variant="ghost"
        color="blackAlpaha.300"
        icon={<OKicon />}
      />
      <IconButton
        as={Link}
        href="https://www.youtube.com/@turkpazar_ru"
        isExternal
        size="lg"
        borderRadius="full"
        variant="ghost"
        color="blackAlpaha.300"
        icon={<YTicon />}
      />
      <IconButton
        as={Link}
        href="https://t.me/TurkPazar_ru"
        isExternal
        size="lg"
        borderRadius="full"
        variant="ghost"
        color="blackAlpaha.300"
        icon={<TGicon />}
      />
      <IconButton
        as={Link}
        href="https://dzen.ru/turkpazar_ru"
        isExternal
        size="lg"
        borderRadius="full"
        variant="ghost"
        color="blackAlpaha.300"
        icon={<DZENicon />}
      />
    </Flex>
  );
};

export default Social;
