import { Image, Box, Flex, Heading } from '@chakra-ui/react';
import React from 'react';
import { Link as rLink } from 'react-router-dom';

const CategoryCard = ({ cat }) => {
  return (
    <Box h="120px" as={rLink} to={`/catalog/${cat.id}`}>
      <Flex
        h="90px"
        mt="30px"
        px="10px"
        pos="relative"
        flexDirection="column"
        alignItems="center"
        borderTopRadius="md"
        bgColor="#FFFFFF"
        bgGradient="radial-gradient(23.49% 151.9% at 2.43% 0%, #007A4C 0%, #9DC41A 27.73%, rgba(255, 255, 255, 0) 100%), radial-gradient(60.92% 157.26% at 100% 0%, rgba(200, 23, 66, 0.8) 0%, rgba(252, 195, 48, 0.8) 48.96%, rgba(216, 216, 216, 0) 100%)"
      >
        {!!cat.img ? (
          <Box pos="absolute" w="full" h="90px" overflow="hidden" bottom="0">
            <Image
              src={cat.img}
              pos="absolute"
              h="90px"
              w="200px"
              mixBlendMode="overlay"
              objectFit="cover"
              right="-40px"
            />
            <Image
              src={cat.img}
              pos="absolute"
              transform="scaleX(-1)"
              h="90px"
              w="120px"
              mixBlendMode="overlay"
              objectFit="cover"
              left="-20px"
            />
          </Box>
        ) : (
          <Heading
            pos="absolute"
            top="5px"
            left="5px"
            right="5px"
            textAlign="right"
            size="lg"
            mixBlendMode="multiply"
            color="gray.100"
          >
            {cat.name}
          </Heading>
        )}
        <Flex
          h="120px"
          mt="-30px"
          pos="relative"
          alignItems="flex-end"
          justifyContent="center"
        >
          <Heading
            mb="20px"
            pl="10px"
            textAlign="left"
            size="sm"
            color="blackAlpha.900"
          >
            {cat.name}
          </Heading>
          {!!cat.img && <Image src={cat.img} h="120px" objectFit="contain" />}
        </Flex>
      </Flex>
      <Box
        w="full"
        h="8px"
        borderBottomRadius="md"
        bgGradient="radial-gradient(100% 11729.68% at 19.9% 0%, #007A4C 0%, #9DC41A 28.65%, #FCC330 74.72%, #C81742 100%)"
      ></Box>
    </Box>
  );
};

export default CategoryCard;
