import React from 'react';
import { LinkIcon } from '@chakra-ui/icons';
import {
  Box,
  Link,
  HStack,
  VStack,
  Icon,
  Input,
  Text,
  Textarea,
  Spacer,
  Button,
  Flex,
  IconButton,
  Divider,
} from '@chakra-ui/react';
import { BiTrash, BiCheck } from 'react-icons/bi';
import { observer } from 'mobx-react-lite';
import { useStore } from '../hooks/useStore';

const LinkCartItem = ({ item }) => {
  const { user } = useStore();
  return (
    <>
      <Flex mb="20px">
        <Box ml={['0', '0', '30px']} w="full" bg="green.50" p="15px">
          <Flex>
            <Box flex="1">
              <Box>
                <Link href={item.link} target="_blank">
                  <HStack>
                    <LinkIcon />
                    <Text fontSize="xs" color="blue">
                      {item.link}
                    </Text>
                  </HStack>
                </Link>
              </Box>
              <Box mt="10px" ml="20px">
                <Text fontSize="sm" mb="5px">
                  Коментарии к товару
                </Text>
                <Text>{item.description}</Text>
              </Box>
            </Box>
            <Box textAlign="center">
              <Text mb="20px">Колличество</Text>
              <Text fontWeight="bold">{item.count}</Text>
            </Box>
          </Flex>
        </Box>

        <Box ml="10px">
          <VStack h="full">
            <Spacer />
            <IconButton
              mb="0"
              onClick={() => user.removeLink(item.id)}
              icon={<Icon as={BiTrash} />}
            />
          </VStack>
        </Box>
      </Flex>
    </>
  );
};

export default observer(LinkCartItem);
