import React from 'react';
import {
  Box,
  Flex,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
} from '@chakra-ui/react';
import { Link as rLink } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import OrderCardBody from './OrderCardBody';

export const orderTag = {
  created: ['gray.200', 'Создана'],
  processing: ['orange.200', 'В обработке'],
  price: ['blue.200', 'Цена получена'],

  checked: ['yellow.200', 'Проверена'],
  waitbill: ['gray.200', 'Выставление счета'],
  invoice: ['green.200', 'Счет выставлен'],
  paid: ['teal.200', 'Оплачен'],
  stock: ['cyan.200', 'Прибыла на склад'],
  assembled: ['blue.200', 'Собран'],
  send: ['purple.200', 'Отправлен'],
  ontheway: ['pink.200', ''],
  confirm: ['green.200', 'Отправка подтверждена'],
  delivered: ['green.200', 'Доставлен'],
  issued: ['green.200', 'Выдан'],
  deleted: ['red.500', 'Удален'],
  modified: ['red.200', 'Изменен'],
  wrong: ['red.200', 'Несоответствие'],
};

const OrderCard = ({ order }) => {
  return (
    <>
      <Flex mb="10px" flexDir={['column', 'column', 'row']}>
        <Flex
          bg={orderTag[order.status][0]}
          textAlign="center"
          borderRight="1px"
          borderRightColor="gray.300"
          borderLeftRadius="md"
          borderRightRadius={['md', 'md', '0']}
          w={['auto', 'auto', '100px']}
          fontSize="xs"
          alignItems="center"
          justifyContent="center"
        >
          {orderTag[order.status][1]}
        </Flex>
        <AccordionItem flex="1">
          <h2>
            <AccordionButton as={Flex} gap={['10px', '10px', '20px']}>
              <Flex
                gap={['5px', '5px', '20px']}
                flexDirection={['column', 'column', 'row']}
              >
                <Box
                  as={rLink}
                  to={`/order/${order.id}`}
                  color="green"
                  fontWeight="bold"
                >
                  {order.code}
                </Box>
                <Box>{new Date(order.orderDate).toLocaleDateString()}</Box>
              </Flex>
              <Box flex="1" alignSelf="center"></Box>
              <Box>{order.comment}</Box>
              {!!order.convertedSumm && (
                <Box fontWeight="bold" color="green">
                  {order.convertedSumm} &#8381;
                </Box>
              )}
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel px={['5px', '10px', '20px']}>
            <OrderCardBody order={order} />
          </AccordionPanel>
        </AccordionItem>
      </Flex>
    </>
  );
};

export default observer(OrderCard);
