import React, { useContext, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
} from '@chakra-ui/react';
import { Link as rLink } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStore } from '../hooks/useStore';

const SupportForm = ({ opened, close, orderId, itemId }) => {
  const { user } = useStore();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [title, setTitle] = useState('');
  const [comment, setComment] = useState('');

  return (
    <>
      <Modal
        isOpen={opened}
        onClose={() => {
          onClose();
          close(false);
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Вопрос в техподдержку</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {user.message || (
              <>
                <FormControl mb="20px">
                  <FormLabel>Тема</FormLabel>
                  <Input
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Опишите проблему</FormLabel>
                  <Textarea
                    onChange={(e) => setComment(e.target.value)}
                  ></Textarea>
                </FormControl>
              </>
            )}
          </ModalBody>

          <ModalFooter>
            {!!user.message || (
              <Button
                colorScheme="blue"
                mr={3}
                onClick={() => user.sendSupport(title, comment)}
                isLoading={user.loading}
              >
                Отправить
              </Button>
            )}
            <Button
              onClick={() => {
                onClose();
                close(false);
                setTitle('');
                setComment('');
                user.setMessage(null);
              }}
            >
              {user.message ? 'OK' : 'Отмена'}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default observer(SupportForm);
