import $api from "../http";

export default class AuthService {
    static async  login(login, password) {
        return $api.post('/auth/login', {login, password});
    }

    static async  register(email, phone, password) {
        return $api.post('/auth/register', {email, phone, password});
    }

    static async  checkPhone(token, smsCode) {
        return $api.post('/auth/check', {token, smsCode});
    }

    static async logout() {
        return $api.get('/auth/logout');
    }

    static async getSmsCode(phone) {
        return $api.post('/auth/code', {phone});
    }

    static async checkUser(user) {
        return $api.post('/auth/checkuser', user);
    }
}