import React, { useEffect, useState } from 'react';
import { Center, Grid, Heading, Spinner } from '@chakra-ui/react';
import ItemCard from '../components/ItemCard';
import Pagination from '../components/Pagination';
import { observer } from 'mobx-react-lite';
import { useStore } from '../hooks/useStore';
import { useParams, Link as rLink } from 'react-router-dom';

const VendorItemsPage = observer(() => {
  const vendorId = useParams().vid;

  const [items, setItems] = useState([]);
  const [vendor, setVendor] = useState(null);

  const { store } = useStore();

  useEffect(() => {
    setItems([]);
    store.getItems({ vid: vendorId });

    return () => {
      store.setItems([]);
    };
  }, []);

  useEffect(() => {
    setItems(store.items);
    // setItems([...items, ...store.items]);
  }, [store.items]);

  return (
    <>
      {/* <BannerSmall
            title={store.category.name}
            to={`/catalog/${catId}`}
            img={store.category.img}
          /> */}
      <Heading mb="50px" fontWeight="medium" color="gray.400">
        {vendor ? vendor.name : ''}
      </Heading>

      {!store.loading ? (
        <Grid
          templateColumns={[
            'repeat(2, 1fr)',
            'repeat(2, 1fr)',
            'repeat(4, 1fr)',
            'repeat(5, 1fr)',
          ]}
          gap={8}
        >
          {items.length || store.loading
            ? items.map((card) => <ItemCard key={card.id} item={card} />)
            : !!store.count || 'No items'}
        </Grid>
      ) : (
        <Center mt="30px">
          <Spinner />
        </Center>
      )}
      <Pagination
        isLoading={store.loading}
        itemsCount={store.count}
        callback={(args) => store.getItems({ vid: vendorId, ...args })}
      />
    </>
  );
});

export default VendorItemsPage;
