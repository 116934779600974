import React, { useState } from 'react';
import {
  Box,
  Flex,
  Image,
  Tag,
  Text,
  HStack,
  Link,
  Button,
} from '@chakra-ui/react';
import { LinkIcon } from '@chakra-ui/icons';
import { orderItemTag } from './OrderItemCard';
import WrongForm from './WrongForm';
import StockImages from './StockImages';
import { useStore } from '../hooks/useStore';

const OrderLinkCard = ({ item, order, setDel, del }) => {
  const { user } = useStore();

  const [wrong, setWrong] = useState(false);
  const [photos, setPhotos] = useState(false);

  const delHandler = (id) => {
    setDel((prew) => [...prew, id]);
  };
  const restoreHandler = (id) => {
    const filtered = del.filter((item) => item !== id);
    setDel(filtered);
  };

  return (
    <Flex
      gap="20px"
      mb="20px"
      alignItems="stretch"
      bg={
        item.status === 'modified'
          ? 'orange.100'
          : wrong
          ? 'orange.100'
          : del.includes(item.id) && 'red.100'
      }
      pr={del.includes(item.id) && '10px'}
      py={del.includes(item.id) && '5px'}
      borderRadius="md"
      flexDirection={['column', 'column', 'row']}
    >
      {!['send', 'delivered', 'issued', 'assembled'].includes(order.status) &&
        (!del.includes(item.id) ? (
          <Flex
            bg={orderItemTag[item.status][0]}
            fontSize="xs"
            borderRightRadius="md"
            borderLeftRadius={['md', 'md', '0']}
            px="10px"
            alignItems="center"
          >
            {orderItemTag[item.status][1]}
          </Flex>
        ) : (
          <Flex
            bg="red"
            color="white"
            px="10px"
            borderRightRadius="md"
            alignItems="center"
          >
            Удален
          </Flex>
        ))}
      <Flex gap="20px">
        <Box>
          {!!item.img && (
            <Image objectFit="cover" boxSize="50px" src={item.img} />
          )}
        </Box>
        <Box flex="1">
          <Box>
            <Link href={item.link} target="_blank">
              <HStack>
                <LinkIcon />
                <Text fontSize="xs" color="blue" noOfLines={1} w="250px">
                  {item.link}
                </Text>
              </HStack>
            </Link>
          </Box>
          <Box>{item.description}</Box>
        </Box>
      </Flex>
      <Flex gap="20px">
        {!!item.price && (
          <Box textAlign="center">
            <Text fontSize="sm">Цена</Text>
            <Text fontWeight="bold" color="green">
              {item.convertedPrice} &#8381;
            </Text>
          </Box>
        )}
        <Box textAlign="center">
          <Text fontSize="sm">Колличество</Text>
          <Text fontWeight="bold">{item.count}</Text>
        </Box>

        {!!item.summ && (
          <Box textAlign="center">
            <Text fontSize="sm">Сумма</Text>
            <Text fontWeight="bold" color="green">
              {item.convertedSumm} &#8381;
            </Text>
          </Box>
        )}
        {item.stockDate && (
          <Box>
            <Button onClick={() => setPhotos(true)} size="xs" h="full">
              Фото со склада
            </Button>
            <StockImages
              orderItem={item.id}
              isOpen={photos}
              onClose={() => setPhotos(false)}
            />
          </Box>
        )}
      </Flex>

      <Flex alignItems="center">
        {item.status === 'checked' &&
          order.status === 'price' &&
          (!del.includes(item.id) ? (
            <Button
              colorScheme="red"
              size="xs"
              onClick={() => delHandler(item.id)}
            >
              Удалить
            </Button>
          ) : (
            <Button
              colorScheme="green"
              size="xs"
              onClick={() => restoreHandler(item.id)}
            >
              Восстановить
            </Button>
          ))}
        {item.status === 'updated' && (
          <Button
            mr="10px"
            colorScheme="green"
            size="xs"
            h="full"
            onClick={() => user.checkItem(item.id)}
            isLoading={user.loading}
          >
            Подтвердить
          </Button>
        )}
        {order.status === 'stock' &&
          ['recive', 'updated'].includes(item.status) &&
          !['send', 'delivered', 'issued', 'assembled', 'confirm'].includes(
            order.status
          ) && <WrongForm order={order} item={item} onWrong={setWrong} />}
      </Flex>
    </Flex>
  );
};

export default OrderLinkCard;
