import React, { useEffect } from 'react';
import Header from './components/Header';
import useRoutes from './routes/useRoutes';
import { Center, Flex, Spinner } from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import useMessage from './hooks/useMessage';
import { spy } from 'mobx';
import { useStore } from './hooks/useStore';

// spy((ev) => {
//   if (ev.type.includes('action')) {
//     console.log(ev);
//   }
// });

const App = observer(() => {
  const { store, user } = useStore();
  const routes = useRoutes(user.isAuth);

  useEffect(() => {
    if (localStorage.getItem('tk')) {
      user.checkAuth();
    }
  }, []);

  useMessage(store.error, () => store.setError(null));

  return (
    <>
      <Header />
      {routes}
    </>
  );
});

export default App;
