import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Heading,
  Image,
  Text,
  Box,
  Spacer,
  Flex,
} from '@chakra-ui/react';
import { Link as rLink } from 'react-router-dom';

const ItemCard = ({ item }) => {
  const col = useRef();
  const [colWidth, setColWidth] = useState(0);

  useEffect(() => {
    setColWidth(col.current.clientWidth);
  }, []);

  return (
    <Flex direction="column" overflow="hidden">
      <Box
        width="100%"
        ref={col}
        height={colWidth}
        as={rLink}
        to={`/item/${item.id}`}
        bg="gray.200"
        borderRadius="4px"
        overflow="hidden"
      >
        {!!item.images.length && (
          <Image
            src={item.images[0].link}
            boxSize="full"
            objectFit="cover"
            backgroundPosition="center"
          />
        )}
      </Box>
      <Box mb="10px" flex="1">
        <Box>
          <Text as="span" color="#9DC41A" fontSize="2xl">
            {item.fullConvertedPrice} &#8381;
          </Text>{' '}
          / {item.count} шт
        </Box>
        <Heading size="sm">{item.name}</Heading>

        <Text noOfLines={2}>{item.description}</Text>
      </Box>
      <Spacer />
      <Button
        as={rLink}
        bgGradient="linear-gradient( 30deg, #007A4C -40%, #9DC41A)"
        to={`/item/${item.id}`}
        size="sm"
        colorScheme="green"
      >
        В корзину
      </Button>
    </Flex>
  );
};

export default ItemCard;
