import React, { useEffect } from 'react';
import {
  Box,
  Heading,
  Text,
  HStack,
  Spacer,
  Button,
  Center,
  Flex,
  Spinner,
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import CatCard from '../components/CatCard';
import { useStore } from '../hooks/useStore';
// import { useNavigate } from 'react-router-dom';
import StockSelect from '../components/StockSelect';
import { Navigate, useNavigate } from 'react-router-dom';

const CartPage = observer(() => {
  const { user } = useStore();
  console.log(user);
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.userData?.status === 'active') {
      if (!user.cart?.items?.length) user.getCart();
    }
  }, []);

  const orderHandler = async () => {
    await user.setOrder();
    navigate('/orders');
  };

  //useMessage(user.error);

  if (user?.userData?.status === 'active') {
    return (
      <>
        {user.loading && !user.cart ? (
          <Center flex="1">
            <Spinner thickness="4px" size="xl" color="green" />
          </Center>
        ) : (
          <Box>
            <Heading mb="20px" fontWeight="medium" color="gray.600">
              Корзина
            </Heading>
            <Flex
              gap="20px"
              alignItems={['stretch', 'stretch', 'start']}
              flexDirection={['column', 'column', 'row']}
            >
              <Box w="full">
                {user.cart && !user.cart?.message
                  ? user.cart?.items.map((item) => (
                      <CatCard key={item.id} item={item} />
                    ))
                  : 'Корзина пуста'}

                <HStack mt="30px">
                  <Spacer />
                  {user.cart?.items?.length &&
                    user.cart?.cartprice / user.cart?.course > 200 && (
                      <Button
                        colorScheme="green"
                        isLoading={user.loading}
                        onClick={orderHandler}
                      >
                        Оформить заказ
                      </Button>
                    )}
                </HStack>
              </Box>

              <Box w={['auto', 'auto', '300px']} p="20px" bg="gray.50">
                <Heading size="md">Сумма заказа</Heading>
                <Text fontSize="2xl" fontWeight="medium" color="teal" mb="30px">
                  {user.cart?.cartprice ? user.cart?.cartprice : 0} &#8381;
                </Text>
                {!!user.cart?.items.length && (
                  <>
                    {user.cart?.cartprice / user.cart?.course < 200 ? (
                      <Box mb="20px" color="orange" fontWeight="bold">
                        Минимальная сумма заказа 200$
                      </Box>
                    ) : (
                      <>
                        <StockSelect />
                        <Center mt="20px">
                          <Button
                            colorScheme="green"
                            isLoading={user.loading}
                            onClick={orderHandler}
                          >
                            Оформить заказ
                          </Button>
                        </Center>
                      </>
                    )}
                  </>
                )}
              </Box>
            </Flex>
          </Box>
        )}
      </>
    );
  }
  return <Navigate to="/inactive" />;
});

export default CartPage;
