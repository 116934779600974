import React from 'react';
import { Outlet } from 'react-router-dom';
import { Box, Flex, Spacer } from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import Footer from '../components/Footer';

const MainPage = () => {
  return (
    <>
      <Box px="8px" mt={['20px', '20px', '40px']}>
        <Box minH="90vh">
          <Flex
            maxW="1400px"
            direction="column"
            px={['8px', '8px', '20px']}
            mx="auto"
          >
            <Outlet />
            <Spacer />
            <Box h="10px"></Box>
          </Flex>
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default MainPage;
