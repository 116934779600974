import React, { useState } from 'react';
import { Box, Text, Flex, Grid, Link, Button } from '@chakra-ui/react';
import SupportForm from './SupportForm';
import Social from './Social';
import { useStore } from '../hooks/useStore';

const Footer = () => {
  const [support, setSupport] = useState(false);
  const { store, user } = useStore();

  return (
    <>
      <Box mt="100px">
        <Box bgGradient="radial-gradient(100% 11729.68% at 19.9% 0%, #007A4C 0%, #9DC41A 28.65%, #FCC330 74.72%, #C81742 100%)">
          <Box
            bg="white"
            borderBottomRadius="md"
            boxShadow="lg"
            h="20px"
            w="full"
          ></Box>
          <Flex flexDirection={['column', 'column', 'row']}>
            <Grid
              templateColumns={[
                'repeat(1, 1fr)',
                'repeat(2, 1fr)',
                'repeat(3, 1fr)',
                'repeat(4, 1fr)',
              ]}
              flex="1"
              p="20px"
              gap={['8px', '10px', '20px']}
              justifyItems="center"
            >
              <Box>
                {user.isAuth || (
                  <Button
                    size="sm"
                    variant="ghost"
                    color="white"
                    _hover={{ color: 'black', bg: 'whiteAlpha.300' }}
                    onClick={() => setSupport(true)}
                  >
                    Обратиться в техподдержку
                  </Button>
                )}
              </Box>
              <Box color="blackAlpha.700">
                <Box>
                  <Link href="https://promo.turkpazar.ru" isExternal>
                    О портале
                  </Link>
                </Box>
                <Box></Box>
              </Box>
              <Box></Box>
              <Box>
                <Social />
              </Box>
            </Grid>
            <Box
              px={['0', '0', '50px']}
              pb={['0', '0', '50px']}
              pt={['0', '0', '20px']}
              color="gray.200"
            >
              <Box
                px="14px"
                py="4px"
                borderBottomRadius={['none', 'none', 'lg']}
                borderTopRadius="lg"
                bg="blackAlpha.600"
                textAlign={['center']}
              >
                <Text as="span" color="#9DC41A">
                  Turk
                </Text>
                <Text as="span" color="#FCC330">
                  Pazar
                </Text>
                <Text as="span" mx="10px">
                  &copy;
                </Text>
                <Text as="span">2023</Text>
              </Box>
            </Box>
          </Flex>
        </Box>
      </Box>

      {user.isAuth || <SupportForm opened={support} close={setSupport} />}
    </>
  );
};

export default Footer;
